import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'thevatra-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressIndicatorComponent {
}
