<div class="toggle-icon"
     [ngClass]="{'toggle-icon--nohover': !hover}"
>
  <div class="toggle-icon__wrap">
    <stusan-icon icon="{{ icon }}"
                 class="toggle-icon__icon"
                 [ngClass]="{'toggle-icon__icon--hidden': state}"
    ></stusan-icon>
    <stusan-icon icon="{{ icon + '-hover' }}"
                 class="toggle-icon__icon toggle-icon__icon--hover"
    ></stusan-icon>
    <stusan-icon icon="{{ icon + '-on' }}"
                 class="toggle-icon__icon toggle-icon__icon--on"
                 [ngClass]="{'toggle-icon__icon--active': state}"
    ></stusan-icon>
  </div>
  <div class="toggle-icon__text"
       [ngClass]="{'toggle-icon__text--active': state}"
       [class.default]="labelColor === 'default'"
       [class.toggle]="labelColor === 'toggle'"
       [class.enabled]="labelColor === 'enabled'"
  >
    {{ label }}
  </div>
</div>
