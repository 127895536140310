import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationMessageComponent } from '@shared/services/notification/components/notification-message/notification-message.component';
import { NotificationMessage } from '@shared/services/notification/classes/notification-info-message';
import { NotificationErrorMessage } from '@shared/services/notification/classes/notification-error-message';
import { StateService } from '@shared/services';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly matSnackBar: MatSnackBar,
    private readonly stateService: StateService,
    private readonly toastrService: ToastrService,
  ) {}

  public showError(message: string, duration: number = 5000): void {
    if (this.stateService.isRecordingBot) {
      return;
    }

    const snackErrorWarningData = new NotificationErrorMessage(message);

    this.matSnackBar.openFromComponent(NotificationMessageComponent, { data: snackErrorWarningData, duration });
  }

  public showInfo(message: string, duration: number = 3000): void {
    if (this.stateService.isRecordingBot) {
      return;
    }

    const snackBarInfoData = new NotificationMessage(message);

    this.matSnackBar.openFromComponent(NotificationMessageComponent, { data: snackBarInfoData, duration });
  }

  public showToastr(message: string) {
    if (this.stateService.isRecordingBot) {
      return;
    }
    const toastService = this.toastrService;
    toastService.success(message, undefined, {
      timeOut: 10000,
      toastClass: 'toast'
    });
    toastService.toasts[0]?.onTap.subscribe((tapped: any) => {
      if (tapped) {
        toastService.clear(toastService.toasts[0].toastId);
      }
    });
  }
}
