import { GlobalPositionStrategy } from '@angular/cdk/overlay/position/global-position-strategy';
import { Overlay, PositionStrategy } from '@angular/cdk/overlay';

import { GlobalPositionStrategyConfig } from '@shared/services/popup/models/global-position-strategy-config.model';

export class PositionStrategyBuilder {
  private globalPositionStrategy: GlobalPositionStrategy;

  constructor(private overlay: Overlay, private globalPositionStrategyConfig: GlobalPositionStrategyConfig) {
    this.globalPositionStrategy = this.overlay.position().global();
  }

  public build(): PositionStrategy {
    return this.globalPositionStrategy as PositionStrategy;
  }

  public centerHorizontally(): PositionStrategyBuilder {
    if (this.globalPositionStrategyConfig.isCentredHorizontally) {
      this.globalPositionStrategy = this.globalPositionStrategy.centerHorizontally();
    }

    return this;
  }

  public centerVertically(): PositionStrategyBuilder {
    if (this.globalPositionStrategyConfig.isCentredVertically) {
      this.globalPositionStrategy = this.globalPositionStrategy.centerVertically();
    }

    return this;
  }

  public right(): PositionStrategyBuilder {
    if (!this.globalPositionStrategyConfig.hasOwnProperty('right') || this.globalPositionStrategyConfig.isCentredHorizontally) {
      return this;
    }

    this.globalPositionStrategy = this.globalPositionStrategy.right(this.globalPositionStrategyConfig.right);

    return this;
  }

  public left(): PositionStrategyBuilder {
    if (!this.globalPositionStrategyConfig.hasOwnProperty('left') || this.globalPositionStrategyConfig.isCentredHorizontally) {
      return this;
    }

    this.globalPositionStrategy = this.globalPositionStrategy.left(this.globalPositionStrategyConfig.left);

    return this;
  }

  public top(): PositionStrategyBuilder {
    if (!this.globalPositionStrategyConfig.hasOwnProperty('top') || this.globalPositionStrategyConfig.isCentredVertically) {
      return this;
    }

    this.globalPositionStrategy = this.globalPositionStrategy.top(this.globalPositionStrategyConfig.top);

    return this;
  }

  public bottom(): PositionStrategyBuilder {
    if (!this.globalPositionStrategyConfig.hasOwnProperty('bottom') || this.globalPositionStrategyConfig.isCentredVertically) {
      return this;
    }

    this.globalPositionStrategy = this.globalPositionStrategy.bottom(this.globalPositionStrategyConfig.bottom);

    return this;
  }

  public reset(): PositionStrategyBuilder {
    this.globalPositionStrategy = this.overlay.position().global();

    return this;
  }
}
