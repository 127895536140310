import { NotificationType } from '@shared/services/notification/enums/notification-type';

export abstract class NotificationMessageBase {
  public backgroundColor: string;
  public type: NotificationType;

  protected constructor(public readonly message: string, public readonly isClosable: boolean = true) {
    this.type = this.getNotificationType();
    this.backgroundColor = this.getNotificationBackgroundColor();
  }

  protected abstract getNotificationType(): NotificationType;
  protected abstract getNotificationBackgroundColor(): string;
}
