import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomizationModel } from '../../../models/customization.model';
import { CustomizationService } from '../../../services/customization.service';
import { PermissionsService } from '@shared/services/permissions/permissions.service';
import { MediaService } from '@shared/services/media.service';
import { StateService } from '@shared/services';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';

@Component({
  selector: 'app-request-permission',
  templateUrl: './request-permission.component.html',
  styleUrls: ['./request-permission.component.scss'],
})

export class RequestPermissionComponent implements OnInit {

  public customConfig: CustomizationModel;
  @Output() hasMediaPermission = new EventEmitter<boolean>();
  public displayPermissionRequestPage = false;
  public isFirefoxOrSafari = false;
  public hasFireFoxOrSafariPermission = true;
  public queryParams: { [key: string]: string } = {};

  constructor(
    private customizationService: CustomizationService,
    private permissionsService: PermissionsService,
    private media: MediaService,
    private stateService: StateService,
    private readonly router: Router,
    private deviceDetector: PlatformDetectorService,
    private readonly route: ActivatedRoute,
  ) {
  }
  ngOnInit() {
    if (this.route.snapshot.queryParams.token) {
      this.queryParams.token = this.route.snapshot.queryParams.token;
    }
    this.customConfig = this.customizationService.config;
    const permissionState = this.stateService.getSavedState('displayPermission', 'string');
    this.isFirefoxOrSafari = this.deviceDetector.isFirefox() || this.deviceDetector.isSafari();
    this.displayPermissionRequestPage = !!(permissionState && permissionState === 'request-perm');
    if (this.isFirefoxOrSafari) {
      this.displayPermissionRequestPage = true;
    }
  }

  requestPermission() {
    if (this.isFirefoxOrSafari && !this.hasFireFoxOrSafariPermission) {
      this.router.navigate(['/'], { queryParams: this.queryParams }).then(() => {
        window.location.reload();
      });
    }
    this.media.devicesGetUserMedia().subscribe(hasPermission => {
      this.stateService.saveState('displayPermission', !hasPermission ? 'check-access' : '');
      if (!this.isFirefoxOrSafari) {
        this.displayPermissionRequestPage = false;
      }
      this.hasMediaPermission.emit(hasPermission);
      this.stateService.isCamBanned = !hasPermission;
      if (this.isFirefoxOrSafari) {
        this.hasFireFoxOrSafariPermission = hasPermission;
      }
    });
  }

  checkAccessPermission() {
    this.permissionsService.checkPermissions().subscribe((hasPermission) => {
      this.stateService.saveState('displayPermission', !hasPermission ? 'request-perm' : '');
      if (!hasPermission) {
        this.displayPermissionRequestPage = true;
        this.router.navigate(['/'], { queryParams: this.queryParams }).then(() => {
          window.location.reload();
        });
      } else {
        this.hasMediaPermission.emit(hasPermission);
        this.stateService.isCamBanned = !hasPermission;
      }
    });
  }
}
