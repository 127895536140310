import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { serverTimestamp } from 'firebase/firestore';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { StateService } from '@shared/services/state.service';
import { Reaction, User } from '../../models/message';
import { arrayUnion } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ReactionsService {
  constructor(
    private readonly firestore: AngularFirestore,
    private readonly auth: AuthService,
    private stateService: StateService,
  ) {}

  public get roomId(): string | number | null {
    return this.stateService.roomId;
  }

  public async toggleReaction(userId: string, myLike: string | number, liked: boolean, myLikes: string[], likeId?: string | number | null) {
    const user = this.auth.currentUser;
    if (!!user) {
      return this.addReaction(user, userId, myLike, liked, myLikes, likeId);
    }
  }

  public async toggleStar(userId: string, myLike: string | number, starred: boolean, myStars: string[]) {
    const user = this.auth.currentUser;
    if (!!user) {
      return this.addStar(user, userId, myLike, starred, myStars);
    }
  }

  public getUser(userId: number): Query<Reaction> {
    console.log(`firestore::Users::getUser ${userId}`);
    return this.firestore.collection<Reaction>('Users').ref
      .where('roomId', '==', this.roomId)
      .where('user.uid', '==', userId)
      .orderBy('sentAt', 'asc');
  }

  public getLike(userId: number): Query<Reaction> {
    console.log(`firestore::Users::getLike ${userId}`);
    return this.firestore.collection<Reaction>('Users').ref
      .where('roomId', '==', this.roomId)
      .where('likeId', '==', userId)
      .orderBy('sentAt', 'asc');
  }

  public getUsers(userIds: string[]): Query<any> {
    console.log('firestore::Users::getUsers');
    return this.firestore.collection<any>('Users').ref
      .where('roomId', '==', this.roomId)
      .where('userId', 'in', userIds);
  }

  private async addReaction(
    user: User,
    userId: string,
    myLike: number | string,
    liked: boolean,
    myLikes: string[],
    likeId?: string | number | null
  ) {
    if (liked) {
      await this.firestore.collection('Users')
        .doc(myLike as string)
        .set({
          likeId,
          likedBy: myLikes.filter(item => item !== myLike),
        }, { merge: true });
    } else {
      await this.firestore.collection('Users')
        .doc(myLike as string)
        .set({
          likeId,
          likedBy: arrayUnion(user.id),
        }, { merge: true });
    }
    if (userId) {
      if (liked) {
        await this.firestore.collection('Users')
          .doc(userId)
          .set({
            myLikes: myLikes.filter(item => item !== myLike),
            sentAt: serverTimestamp(),
          }, { merge: true });
        return;
      } else {
        await this.firestore.collection('Users')
          .doc(userId)
          .set({
            myLikes: arrayUnion(myLike),
          }, { merge: true });
      }
    } else {
      await this.firestore.collection('Users')
        .doc(user.id)
        .set({
          roomId: this.roomId,
          sentAt: serverTimestamp(),
          user: {
            uid: user.id,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          myLikes: [myLike],
        }, { merge: true });
    }
  }

  public async toggleLike(myLike: number | string, likeId: string | number | null) {
    await this.firestore.collection('Users')
      .doc(myLike as string)
      .set({
        likeId
      }, { merge: true });
  }

  private async addStar(user: User, userId: string, myLike: number | string, starred: boolean, myStars: string[]) {
    await this.firestore.collection('Users')
      .doc(myLike as string)
      .set({
        sentAt: serverTimestamp(),
      }, { merge: true });
    if (userId) {
      if (starred) {
        await this.firestore.collection('Users')
          .doc(userId)
          .set({
            myStars: myStars.filter(star => star !== myLike),
          }, { merge: true });
        return;
      } else {
        await this.firestore.collection('Users')
          .doc(userId)
          .set({
            myStars: arrayUnion(myLike),
          }, { merge: true });
      }
    } else {
      await this.firestore.collection('Users')
        .doc(user.id)
        .set({
          myStars: [myLike],
          sentAt: serverTimestamp(),
        }, { merge: true });
    }
  }
}
