<div class="logo-wrapper">
  <a *ngIf="customConfig.homeLogo" href="https://trembit.com/" target="_blank">
  <img class="perm-logo" src="{{ customConfig.homeLogo }}">
</a>
</div>
<div class="permission-wrapper">
  <div *ngIf="displayPermissionRequestPage; else accessWarn">
    <div class="permission-access-container">
      <div class="unblockPermissions">
        <p class="title">For others to see and hear you, your browser will request access to your camera and microphone.</p>
        <div *ngIf="isFirefoxOrSafari && !hasFireFoxOrSafariPermission" class="permission-warning">Your camera and microphone are blocked.</div>
        <ol class="list-ol">
          <li>
            <div>
              You can still turn them back off at any time.
            </div>
          </li>
          <li>
            <div>
              Make sure your devices are connected.
            </div>
          </li>
        </ol>
      </div>
      <div class="btn-holder">
        <button class="button button--create-room" (click)="requestPermission()">
          {{hasFireFoxOrSafariPermission ? 'Request permission' : 'Try again'}}
        </button>
      </div>
    </div>
  </div>
  <ng-template #accessWarn>
    <div class="permission-access-container">
      <div class="unblockPermissions">
        <p class="title">It seems your browser is blocked from accessing your camera and microphone.</p>
        <ol class="list-ol">
          <li>
            <div>
              Click the camera icon in the far right of the URL bar.
            </div>
          </li>
          <li>
            <div>Select <em>‘Always allow’</em> followed by <em>‘Done’</em>.
              <div class="chrome-allow" aria-hidden="true">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                     class="chromeAllowRadio-33qj">
                  <rect x="1" y="1" width="14" height="14" rx="7" stroke="#261362" stroke-width="2"></rect>
                  <rect x="4" y="4" width="8" height="8" rx="4" fill="#261362"></rect>
                </svg>
                Always allow https://vatra.live to access your camera and microphone
              </div>
            </div>
          </li>
          <li>
            <div><span>Finally, click the <em>‘Try Again after allowing access’</em> button below.</span></div>
          </li>
        </ol>
      </div>
      <div class="btn-holder">
        <button class="button button--create-room" (click)="checkAccessPermission()">
          I’ve allowed access
        </button>
      </div>
    </div>
  </ng-template>
</div>
