<stusan-control-button (click)="toggleStar($event)"
                       [class.starred]="starred"
>
  <stusan-icon icon="star"></stusan-icon>
</stusan-control-button>
<stusan-control-button (click)="setTimer($event)"
                       [class.disabled]="disabled"
>
  <stusan-icon icon="like"></stusan-icon>
</stusan-control-button>
