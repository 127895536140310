import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { VIEW_SIZE } from '@shared/services/view/constants/view-sizes.constant';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewService implements OnDestroy {
  public isMobileView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDesktopView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroyed$ = new Subject<boolean>();

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.handleViewChanges();
  }

  private handleViewChanges(): void {
    this.breakpointObserver
      .observe(`(max-width: ${VIEW_SIZE.tablet})`)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        const isMobileView: boolean = state.matches;
        const isDesktopView = !(state.matches);

        this.isDesktopView$.next(isDesktopView);
        this.isMobileView$.next(isMobileView);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
