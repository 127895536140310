<div #origin class="origin" [class.opened]="opened" [title]="textValue" (click)="toggle()" >
  <span>{{textValue}}</span>
  <stusan-icon icon="circle-arrow-down"></stusan-icon>
</div>
<ng-template #dropdown>
  <div class="dropdown-container">
    <div *ngFor="let option of options" class="option" (click)="select(option.value)" [title]="option.label">
      {{option.label}}
    </div>
  </div>
</ng-template>
