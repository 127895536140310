import { Injectable } from '@angular/core';
import { NotificationService } from '@shared/services/notification/notification.service';
import { StateService } from '@shared/services/state.service';
import { JanusService } from '@shared/services/janus.service';
import { MediaService } from '@shared/services/media.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlsService {
  private cameraMutingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private micMutingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public cameraMuting$ = this.cameraMutingSubject$.asObservable();
  public micMuting$ = this.micMutingSubject$.asObservable();

  constructor(
    private readonly stateService: StateService,
    private readonly mediaService: MediaService,
    private readonly notificationService: NotificationService,
    private readonly janusService: JanusService,
  ) { }

  public toggleMic(): void {
    if (this.stateService.isCamBanned) {
      this.showNotificationAccessToCameraAndMicroWasDenied();
      return;
    }

    if (!this.micMutingSubject$.value) {
      this.micMutingSubject$.next(true);
      this.janusService.sendAudio(!this.stateService.audioEnabled);

      setTimeout(() => {
        // TODO RTDB temporal enable button
        this.micMutingSubject$.next(false);
        // update voice activity detection
        this.mediaService.voiceActivityDetect(this.janusService.localStream$.getValue());
      }, 500);
    }
  }

  public toggleCam(): void {
    if (this.stateService.isCamBanned) {
      this.showNotificationAccessToCameraAndMicroWasDenied();
      return;
    }

    if (!this.cameraMutingSubject$.value) {
      this.cameraMutingSubject$.next(true);

      this.stateService.videoEnabled = !this.stateService.videoEnabled;
      this.janusService.handleWebCamStream();

      setTimeout(() => {
        // TODO temporal enable button
        this.cameraMutingSubject$.next(false);
      }, 500);
    }
  }

  private showNotificationAccessToCameraAndMicroWasDenied(): void {
    const thirtySecondsNotificationDuration = 300000;

    this.notificationService.showError(
      'Web camera or microphone access was denied. Please grant permission it in your browser and refresh this page.',
      thirtySecondsNotificationDuration
    );
  }
}
