import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorModel } from '../models/error.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RecordingService {
  public queryParams: { [key: string]: string } = {};
  constructor(private http: HttpClient,
              private readonly route: ActivatedRoute,
  ) { }

  // 'https://api-staging.vatra.live/api/v2/recordings/list/:roomId',
  public getList(roomName: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/recordings/list/${roomName}`).pipe(
      map((list: any) => list),
      catchError(this.handleError<any>('getList'))
    );
  }
  //  'https://api-staging.vatra.live/api/v2/recordings/:roomId/start'
  public start(roomName: string, roomUrl: string): Observable<any> {
    const recordingStartRequestBody = { hostRecording: roomUrl };

    return this.http.post(`${environment.apiUrl}/api/v2/recordings/${roomName}/start`, recordingStartRequestBody).pipe(
      map((res: any) => res),
      catchError(this.handleError<any>('start'))
    );
  }

  //  'https://api-staging.vatra.live/api/v2/recordings/:roomId/confirm'
  public confirm(roomName: string): Observable<any> {
    let headers = new HttpHeaders();
    if (this.route.snapshot.queryParams.apiKey) {
      this.queryParams.apiKey = this.route.snapshot.queryParams.apiKey;
      headers = headers.set('x-api-key', this.queryParams.apiKey);
    }
    return this.http.post(
      `${environment.apiUrl}/api/v2/recordings/${roomName}/confirm`,
      {},
      { headers }
    );
  }

  //  'https://api-staging.vatra.live/api/v2/recordings/:roomId/stop'
  public stop(roomName: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v2/recordings/${roomName}/stop`, {}).pipe(
      map((res: any) => res),
      catchError(this.handleError<any>('stop'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: ErrorModel): Observable<T> => throwError({ message: error });
  }
}
