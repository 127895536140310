/**
 * Ensures that window object has given object structure:
 * Example: ensureGlobalObject('foo.bar.room') will create structure in window: { foo: { bar: { room: {} } } } If it not exists.
 * It is safe to require path where you already have some values.
 *
 * @param path: dot-separated path
 * @returns object in this path (created or already existing)
 */
export const ensureGlobalObject: (path: string) => any = (path) => {
  const parts = path.split('.');
  let obj: any = window;
  for (const field of parts) {
    if (!obj[field]) {
      obj[field] = {};
    }
    obj = obj[field];
  }
  return obj;
};
