import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ObserversModule } from '@angular/cdk/observers';
import { PlatformModule } from '@angular/cdk/platform';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent } from './components/icon/icon.component';
import { ControlButtonComponent } from './components/control-button/control-button.component';
import { MicWarningPopupComponent } from './components/mic-warning-popup/mic-warning-popup.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { VideoComponent } from './components/video/video.component';
import { SettingsComponent } from '@shared/components/settings/settings.component';
import { GetFirstWord } from './pipes/first-word.pipe';
import { SoundService } from './services/sound.service';
import { NotificationMessageComponent } from './services/notification/components/notification-message/notification-message.component';
import { InfoPopupWrapperComponent } from './components/popups/base/info-popup-wrapper/info-popup-wrapper.component';
import {
  CantShareYourScreenPopupComponent
} from './components/popups/cant-share-your-screen-popup/cant-share-your-screen-popup.component';
import { RequestPermissionComponent } from './components/request-permission/request-permission.component';
import { MatMenuModule } from '@angular/material/menu';
import {
  ScreenShareNotAvailablePopupComponent
} from '@shared/components/popups/screen-share-not-available-popup/screen-share-not-available-popup.component';
import { FinishComponent } from '@shared/components/finish/finish.component';
import { ConfirmationPopupComponent } from '@shared/components/popups/confirmation-popup/confirmation-popup.component';
import { ToggleIconComponent } from '@shared/components/toggle-icon/toggle-icon.component';
import { ProgressIndicatorComponent } from '@shared/components/progress-indicator/progress-indicator.component';
import { EqualizerComponent } from '@shared/components/equalizer/equalizer.component';
import { LinkifyPipe } from '@shared/pipes/linkify.pipe';
import { AuthComponent } from './components/auth/auth.component';
import { RouterModule } from '@angular/router';
import { LikeComponent } from './components/like/like.component';
import { ProfileComponent } from '@shared/components/profile/profile.component';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { ErrorComponent } from '@shared/components/error/error.component';
import { LottieModule } from 'ngx-lottie';

const components = [
  LinkifyPipe,
  GetFirstWord,
  IconComponent,
  ControlButtonComponent,
  MicWarningPopupComponent,
  ToggleComponent,
  DropdownComponent,
  VideoComponent,
  SettingsComponent,
  NotificationMessageComponent,
  InfoPopupWrapperComponent,
  RequestPermissionComponent,
  ScreenShareNotAvailablePopupComponent,
  FinishComponent,
  ErrorComponent,
  ToggleIconComponent,
  ProgressIndicatorComponent,
  EqualizerComponent,
  AuthComponent,
  ProfileComponent,
];

@NgModule({
  declarations: [
    ...components,
    CantShareYourScreenPopupComponent,
    ConfirmationPopupComponent,
    LikeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    ObserversModule,
    MatSnackBarModule,
    PlatformModule,
    MatTooltipModule,
    MatMenuModule,
    MatSelectModule,
    RouterModule,
    ToastrModule.forRoot(),
    LottieModule,
  ],
  exports: [
    ...components,
    ScrollingModule,
    ObserversModule,
    PlatformModule,
  ],
  providers: [
    SoundService
  ],
})
export class SharedModule { }
