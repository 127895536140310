import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { PopupRef } from '@shared/services/popup/popup.ref';

@Component({
  selector: 'thevatra-info-popup-wrapper',
  templateUrl: './info-popup-wrapper.component.html',
  styleUrls: ['./info-popup-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPopupWrapperComponent {}
