<thevatra-info-popup-wrapper>
  <span info-popup-title>
    {{ !isMacOs ? 'Can\'t share your screen' : 'Problem sharing screen' }}
  </span>
  <span info-popup-content>
    <ng-container *ngIf="!isMacOs else popupMacOsContentMessage">
      Please grant permissions <br> for screen sharing in system settings
    </ng-container>
  </span>
  <div class="cant-share-your-screen-popup__actions" info-popup-actions>
    <button (click)="closePopup()">OK</button>
  </div>
</thevatra-info-popup-wrapper>

<ng-template #popupMacOsContentMessage>
  Your browser might not have MacOS permission to access screen.<br/>
  Go to
  <a class="system-preferences-link"
     href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture">
    System Preferences
  </a>
</ng-template>
