import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { StateService } from './stusan/shared/services/state.service';
import { ThemeType } from './stusan/enums';
import { CustomizationService } from './stusan/services/customization.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'stusan-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private favIcon: HTMLLinkElement;
  private appleFavIcon: HTMLLinkElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public customizationService: CustomizationService,
    private stateService: StateService,
    private title: Title,
    private overlayContainer: OverlayContainer,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.stateService.startTime = Date.now();
    this.setFaviconDynamically();
    this.disableMaterialOverlayAnimation();

    this.title.setTitle(this.customizationService.config.title as string);
    // this.document.documentElement.setAttribute('data-service', this.customizationService.config.service);
    if (this.customizationService.config.theme === 'blue') {
      this.stateService.theme = ThemeType.blue;
    } else {
      this.stateService.theme = ThemeType.light;
    }

    this.stateService.theme$
      .subscribe((theme: ThemeType) => {
        this.document.documentElement.setAttribute('data-theme', theme);
      });
  }

  private disableMaterialOverlayAnimation(): void {
    const disableAnimations = true;

    // get overlay container to set property that disables animations
    const overlayContainerElement: HTMLElement = this.overlayContainer.getContainerElement();

    // angular animations renderer hooks up the logic to disable animations into setProperty
    this.renderer.setProperty(overlayContainerElement, '@.disabled', disableAnimations);
  }

  private setFaviconDynamically(): void {
    this.favIcon = this.document.querySelector('#appIcon') as HTMLLinkElement;
    this.appleFavIcon = this.document.querySelector('#appAppleIcon') as HTMLLinkElement;

    this.favIcon.href = this.customizationService.config.favicon;
    this.appleFavIcon.href = this.customizationService.config.faviconApple;
  }
}
