import { Injectable } from '@angular/core';
import { DeviceDetectorService, OS } from 'ngx-device-detector';
import { DeviceInfo } from 'ngx-device-detector/lib/device-detector.service';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})

export class PlatformDetectorService {
  private deviceInfo: DeviceInfo;
  private os: string;
  constructor(private readonly platform: Platform, private deviceDetectorService: DeviceDetectorService) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    switch (this.deviceInfo.os) {
      case OS.MAC:
        this.os = 'mac';
        break;
      case OS.IOS:
        this.os = 'ios';
        break;
      case OS.ANDROID:
      case OS.WINDOWS_PHONE:
        this.os = 'android';
        break;
      default:
        this.os = 'windows';
    }
  }

  public isMac(): boolean {
    return this.os === 'mac';
  }

  public isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  public isIPad(): boolean {
    return /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document || /iPad/.test(navigator.userAgent);
  }

  public isIphone(): boolean {
    return ['iPhone'].includes(navigator.platform);
  }

  public isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  public isAndroid(): boolean {
    return this.os === 'android';
  }

  public isMobileByDetector(): boolean {
    return this.deviceDetectorService.isMobile();
  }

  public isTablet(): boolean {
    return this.deviceDetectorService.isTablet();
  }

  public isDesktop(): boolean {
    return this.deviceDetectorService.isDesktop();
  }

  public isBrowserSafari(): boolean {
    return this.deviceInfo.browser === 'Safari';
  }

  public isSafariDesktop(): boolean {
    return this.isBrowserSafari() && this.isDesktop();
  }

  isFirefox(): boolean {
    return this.deviceInfo.browser === 'Firefox';
  }

  isChrome(): boolean {
    return this.deviceInfo.browser === 'Chrome';
  }
}
