import { envDefaults, EnvironmentSettings } from './environment.type';

export const environment: EnvironmentSettings = {
  ...envDefaults,
  buildNumber: `140`,
  production: true,
  sentryOrigin: 'vatra.live',
  envName: 'prod',
  apiUrl: 'https://api.vatra.live',
  janus: {
    debug: false,
    stringRoomIds: false,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDCCfyfm9b7Ez1PrPhfUrmvaDWSnN7shcM',
    authDomain: 'stusan.firebaseapp.com',
    projectId: 'stusan',
    storageBucket: 'stusan.appspot.com',
    messagingSenderId: '492047507532',
    appId: '1:492047507532:web:ef96e6970aa4fa199354e9'
  },
};
