<div class="logo-wrapper"
     *ngIf="!customizationService.isSirius()"
>
  <a *ngIf="customConfig.homeLogo" href="https://trembit.com/" target="_blank">
    <img class="perm-logo" src="{{ customConfig.homeLogo }}">
  </a>
</div>
<div class="finish">
  <div class="finish__inner">Webinar has been ended.</div>
  <a [attr.href]="customConfig.feedbackUrl"
     target="_blank"
     class="feedback"
  >
    <stusan-icon icon="feedback"
                 style="width: 22px; height: 22px;"
                 [style.fill]="'#000'"
    ></stusan-icon>
    Feedback
  </a>
</div>
