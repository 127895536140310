<video [muted]="true" class="video-preview is-own" #videoPreview autoplay playsinline muted disablepictureinpicture autopictureinpicture="false"
       [hidden]="!previewStream || !this.stateService.isCamEnabled"></video>
<div class="login"
     [ngClass]="{'sirius': isSirius}">
  <div class="login__main" role="main" automationid="loginMain">
    <app-request-permission *ngIf="!hasMediaPermission; else displayLoginPage" (hasMediaPermission)="onMediaPermissionChange($event)"></app-request-permission>
    <ng-template #displayLoginPage>
      <a *ngIf="!isSirius && customConfig.homeLogo" href="https://trembit.com/" target="_blank">
        <img class="login__logo" src="{{ customConfig.homeLogo }}">
      </a>

      <img *ngIf="isSirius && customConfig.homeLogo" class="login__logo" src="{{ customConfig.homeLogo }}" automationId="loginLogo">

      <p *ngIf="customConfig.homeShowDescription" class="login__intro">
        Branded video conferencing for you
      </p>
      <p *ngIf="customConfig.homeShowDescription">
        Set up the camera and jump into the video chat.<br/>
        No account required.
      </p>

      <router-outlet></router-outlet>

     <!--      <thevatra-form></thevatra-form>-->

      <div class="login__options" *ngIf="!stateService.isCamBanned" automationId="loginOptions">
        <div class="login__option" automationId="muteOptions">
          <stusan-control-button (click)="toggleMic()">
            <stusan-toggle-icon [label]="stateService.audioEnabled ? 'Mute' : 'Unmute'"
                                [labelColor]="stateService.audioEnabled ? 'default' : 'enabled'"
                                [icon]="stateService.audioEnabled ? 'menu-mic-on' : 'menu-mic-off'"
                                [hover]="stateService.audioEnabled"
            ></stusan-toggle-icon>
          </stusan-control-button>
        </div>
        <div class="login__option" automationId="camOptions">
          <stusan-control-button (click)="toggleCam()">
            <stusan-toggle-icon [label]="stateService.videoEnabled ? 'Stop Cam' : 'Start Cam'"
                                [labelColor]="stateService.videoEnabled ? 'default' : 'enabled'"
                                [icon]="stateService.videoEnabled ? 'menu-cam-on' : 'menu-cam-off'"
                                [hover]="stateService.videoEnabled"
            ></stusan-toggle-icon>
          </stusan-control-button>
        </div>
        <div class="login__option" automationId="settingsOptions">
          <stusan-control-button (click)="openSettings()">
            <stusan-toggle-icon [label]="'Settings'"
                                [icon]="'menu-settings'"
            ></stusan-toggle-icon>
          </stusan-control-button>
        </div>
      </div>
      <div *ngIf="microphoneAccess === 'not_found'">
        <span>Sorry, but there aren't available audio devices to create/join room</span>
      </div>
      <div *ngIf="stateService.isCamBanned">
        <thevatra-mic-warning-popup></thevatra-mic-warning-popup>
      </div>

      <p *ngIf="customConfig.homeShowDescription">TheVatra is a White Label WebRTC based platform and can be easily
        integrated into your business.</p>
    </ng-template>
  </div>
  <footer class="login__footer" automationId="loginFooter">
    <a *ngIf="customConfig.homeShowAuthors" href="https://trembit.com/" target="_blank">
<!--      <img class="login__trembit-logo" src="../assets/stusan/images/trembit-logo.svg" alt="">-->
      <svg class="login__trembit-logo" width="64" height="10" fill="none" xmlns="http://www.w3.org/2000/svg" automationId="loginLogo">
        <path fill="var(--text-color)" fill-rule="evenodd" clip-rule="evenodd" d="M0 2.69h3.017V10H6.26V2.69h3.016V0H0v2.69zm14.354 4.003L16.494 10h2.968l-2.4-3.576a3.052 3.052 0 0 0 1.523-1.171 2.968 2.968 0 0 0 .505-1.836c0-2.184-1.526-3.402-3.714-3.402h-4.607V10h2.66V6.693h.925zm-.925-4.494h1.67c.958 0 1.38.474 1.38 1.202s-.438 1.203-1.38 1.203h-1.67V2.199zM21.052 0v9.985h6.958V8.149h-4.85V5.774h4.412V4.003h-4.411V1.819h4.849V0h-6.958zm14.224 7.849l3.13-4.842V10h1.703V.015h-1.556L35.26 5.223 31.935.015h-1.622V10h1.703V3.006l3.179 4.842h.081zm12.927-3.023c.46-.147.856-.44 1.13-.831.272-.392.406-.861.379-1.335C49.712.935 48.447 0 46.322 0h-3.374v9.984h3.698c2.108 0 3.405-.997 3.405-2.817a2.35 2.35 0 0 0-.507-1.49 2.42 2.42 0 0 0-1.341-.852zm-3.957-.474v-3.23h2.092c1.363 0 2.125.507 2.125 1.6 0 1.091-.746 1.63-2.125 1.63h-2.092zm0 1.076h2.416c1.378 0 2.125.667 2.125 1.725 0 1.045-.73 1.724-2.125 1.724h-2.416v-3.45zM53.376 0h-.843v9.985h.843V0zm2.336.49h3.876V10h.52V.49H64V0h-8.272v.49h-.016z"/>
      </svg>
    </a>
    <span *ngIf="customConfig.homeShowAuthors"> Support and development</span>
  </footer>
</div>
