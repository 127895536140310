import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/message';
import * as jose from 'jose';
import { environment } from '../../../../environments/environment';
import { StateService } from '@shared/services/state.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private user: BehaviorSubject<User | null | undefined> = new BehaviorSubject<User | null | undefined>(undefined);

  public get user$(): Observable<User | null | undefined> {
    return this.user.asObservable();
  }

  public get isHost$(): Observable<boolean> {
    return this.user.pipe(
      map(u => !!u && u.role === 'host')
    );
  }

  public get currentUser(): User | null | undefined {
    return this.user.getValue();
  }

  public get token(): string | undefined {
    return this.user.getValue()?.token;
  }

  constructor(
    public readonly stateService: StateService,
  ) {}

  async parseToken(token: string, roomName: string): Promise<User | null> {
    try {
      if (!!environment.jwtAuth && token) {
        const env =  environment.jwtAuth as any;
        const algorithm = env.algorithm;
        const spki = env.publicKey;
        const ecPublicKey = await jose.importSPKI(spki, algorithm);
        const { payload } = await jose.jwtVerify(token, ecPublicKey);
        const isValidPayload = payload.name && payload.role && payload.webinarId;
        const isValidWebinar = payload.webinarId === roomName;
        this.user.next((isValidPayload && isValidWebinar) ? {
          displayName: payload.name,
          role: payload.role,
          webinarId: payload.webinarId,
          token,
        } : null as any);
      } else {
        this.user.next({
          displayName: this.stateService.userName,
          role: 'host',
        } as any);
      }
      return this.user.getValue() as User | null;
    } catch {
      return null;
    }
  }
}
