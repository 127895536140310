import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CustomizationModel } from '../../../models/customization.model';
import { CustomizationService } from '../../../services/customization.service';

@Component({
  selector: 'stusan-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinishComponent implements OnInit {
  public customConfig: CustomizationModel;

  constructor(public customizationService: CustomizationService) { }

  ngOnInit() {
    this.customConfig = this.customizationService.config;
  }
}
