import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StateService } from '@shared/services';
import { CustomizationService } from '../../services/customization.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              public stateService: StateService,
              public customizationService: CustomizationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject): any => {
      if (this.customizationService.config.isAuthEnabled) {
        reject(false);
        this.logout();
      } else {
        resolve(true);
      }
    });
  }

  logout() {
    this.stateService.isLoggedIn = false;
    this.stateService.userName = null;
    this.router.navigate(['/error'], { queryParams: {
      code: 4200,
      message: 'You are not allowed to join webinar.'
    } }).then();
  }
}
