<div class="info-popup-wrapper">
  <p class="info-popup-wrapper__title">
    <ng-content select="[info-popup-title]"></ng-content>
  </p>
  <div class="info-popup-wrapper__content">
    <ng-content select="[info-popup-content]"></ng-content>
  </div>
  <div class="info-popup-wrapper__actions">
    <ng-content select="[info-popup-actions]"></ng-content>
  </div>
</div>
