import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'thevatra-equalizer',
  templateUrl: './equalizer.component.html',
  styleUrls: ['./equalizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EqualizerComponent implements OnInit {
  @Input() color = '#fff';
  @Input() size = '24px';
  constructor() { }

  ngOnInit(): void {
  }

}
