// Declared in separate file to avoid circular dependencies
export type EnvironmentSettings = {
  production: boolean;
  buildNumber: string | undefined;
  sentryDSN: any;
  sentryOrigin: string;
  callstats: {
    enabled: boolean;
    appId: number;
    appSecret: string;
  };
  envName: string;
  apiUrl: string;
  janus: {
    debug: boolean;
    stringRoomIds: boolean;
    server?: string;
  };
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  jwtAuth: any;
  signupFlowEnabled: boolean;
  observerUrl?: string;
  ipiApi: string;
  weatherApi: string;
  weatherKey: string;
};

export const envDefaults = {
  buildNumber: undefined,

  sentryDSN: 'https://9ec1cab4a51049a8a1fc6922ca2ac9c6@sentry.trembit.com/2',
  sentryOrigin: 'local',

  callstats: {
    enabled: false,
    appId: 388947365,
    appSecret: '8fYDn7+YwaIE:GnAv9ncP3hCKQtUk24j/qasNAmNrlv1F1TEUpejaUnQ='
  },

  jwtAuth: null,
  signupFlowEnabled: false,

  ipiApi: 'https://ipapi.co/json/',
  weatherApi: 'https://api.openweathermap.org/data/2.5/weather',
  weatherKey: '5e6dced8735129a3b2e2a90b7e68c714',

  //observerUrl: 'wss://observer.vatra.live/samples/service-vatra/mediaUnit-vatra-staging',
  observerUrl: undefined,
};
