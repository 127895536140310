<div class="icon-outer s-control-btn"
     [class.icon-outer_small]="type === 'small'"
     [style.backgroundColor]="iconBg"
     [attr.aria-label]="ariaLabel ? ariaLabel : label"
     [matTooltip]="tooltipText"
     [matTooltipDisabled]="isTooltipDisabled">
  <ng-content></ng-content>
</div>
<span class="label-text"
      [style.color]="labelColor"
>
  {{ label }}
</span>
