import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BrandModel } from '../../models/brand.model';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient,) {}
  janusUrl$ = new BehaviorSubject<string | null>(null);
  get janusUrl() {
    return this.janusUrl$.getValue();
  }
  set janusUrl(value: string | null) {
    this.janusUrl$.next(value);
  }
  // getBranding(brand: string) {
  //   return this.http.get('config');
  // }
  getBranding(brand: string): Observable<BrandModel> {
    const brands = [
      {
        logoUrl: '/assets/brands/bikelab.svg',
        brandName: 'Bikelab',
        subDomain: 'bikelab.vatra.live'
      },
      {
        logoUrl: '/assets/brands/veloplaneta.svg',
        brandName: 'PRO Veloplaneta',
        subDomain: 'veloplaneta.vatra.live',
        backgroundColor: '#f9f9f9',
        textColor: '#333',
        menuColor: '#666'
      }
    ] as BrandModel[];
    return of(brands.find(item => item.subDomain === brand) || {
      logoUrl: '/assets/stusan/images/logo.png',
      brandName: 'Vatra',
      subDomain: 'vatra.live'
    });
  }

  setThemeColors(
    backgroundColor: string,
    textColor: string,
    menuColor: string
  ) {
    const styles = [
      {
        name: 'background-color',
        value: backgroundColor
      },
      {
        name: 'menu-color',
        value: menuColor
      },
      {
        name: 'text-color',
        value: textColor
      },
      {
        name: 'menu-item-static',
        value: menuColor
      },
      {
        name: 'icon-bg-color',
        value: menuColor
      },
    ];

    styles.forEach((data: any) => {
      if (data.name && data.value) {
        document.documentElement.style.setProperty(`--${data.name}`, data.value);
      }
    });
  }

  isScreen(displayName: string): boolean {
    return displayName.includes('screen');
  }
}
