import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '../../models/message';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { StateService } from '@shared/services/state.service';
import { serverTimestamp } from 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  public get user$(): Observable<User | null> {
    return this.user.asObservable();
  }

  public get roomId(): string | number | null {
    return this.stateService.roomId;
  }

  public get userId(): string | number | null {
    return this.stateService.userId;
  }

  public get currentUser(): User | null {
    const user = firebase.auth().currentUser as any;
    if (user?.uid) {
      this.stateService.userName = user.displayName as string;
      this.user.next({
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      } as any);
    }
    return this.user.getValue() as User | null;
  }

  constructor(
    private readonly auth: AngularFireAuth,
    public readonly stateService: StateService,
    private readonly firestore: AngularFirestore,
  ) {}

  // Sign in as anonymous
  async anonymousAuth(displayName: string): Promise<User | null> {
    const res = await this.auth.signInAnonymously();
    if (res.user?.uid) {
      await res.user.updateProfile({  displayName });
      this.user.next({
        id: res.user.uid,
        displayName: res.user.displayName,
      } as any);
    }
    return this.user.getValue() as User | null;
  }

  facebookAuth() {
    return this.authLogin(new FacebookAuthProvider());
  }

  googleAuth() {
    return this.authLogin(new GoogleAuthProvider());
  }

  async authLogin(provider: any): Promise<User | null> {
    const res = await this.auth.signInWithPopup(provider);
    if (res.user?.uid) {
      await res.user.updateProfile({  photoURL: res.user.photoURL });
      this.user.next({
        id: res.user.uid,
        displayName: res.user.displayName,
        photoURL: res.user.photoURL,
      } as any);
    }
    return this.user.getValue() as User | null;
  }

  async updateProfile(displayName: string | undefined): Promise<any> {
    const user = firebase.auth().currentUser as any;
    await user.updateProfile({ displayName });
  }

  async createUser(user: User) {
    await this.firestore.collection('Users')
      .doc(user.id)
      .set({
        roomId: this.roomId,
        userId: this.userId,
        sentAt: serverTimestamp(),
        user: {
          uid: user.id,
          displayName: user.displayName,
          photoURL: user.photoURL,
        },
      }, { merge: true });
  }

  async setUserId(userId: string) {
    const user = firebase.auth().currentUser as any;
    await this.firestore.collection('Users')
      .doc(user.uid)
      .set({
        userId,
      }, { merge: true });
  }

  async setUserStatus(status: string) {
    const user = firebase.auth().currentUser as any;
    await this.firestore.collection('Users')
      .doc(user.uid)
      .set({
        roomId: this.roomId,
        user: {
          status
        },
      }, { merge: true });
  }

  async logout() {
    await this.auth.signOut();
  }
}
