import { ConnectionInfoModel } from '@models';

/**
 * Calculates short developer friendly stats.
 */
export const generateStringBitrate: (
  isPublisher: boolean,
  inVideoTrackLabel: string,
  inAudioTrackLabel: string,  // NOTE: always undefined for now
  videoInfo: {
    inbound: {
      bitrate: number;
      frameHeight: number;
      frameWidth: number;
      framesPerSecond: number;
      mimeType: string;
      trackIdentifier: string;
    }[];
    outbound: { bitrate: number; frameHeight: number; frameWidth: number; framesPerSecond: number; encoderImplementation: string }[];
  },
  audioInfo: {
    inbound: { bitrate: number; trackIdentifier: string; mimeType: string }[];
    outbound: { bitrate: number }[];
  }
) => ConnectionInfoModel = (isPublisher, inVideoTrackLabel, inAudioTrackLabel, videoInfo, audioInfo): ConnectionInfoModel => {

  const info: ConnectionInfoModel = {};
  const sumBitrates = (items: { bitrate: number }[]) =>
    items
      .map((e) => e.bitrate)
      .reduce((e1, e2) => e1 + e2, 0);
  const toKbps = (v: number) => `${(v / 1024).toFixed(2)}`;
  const inbound = [...videoInfo.inbound, ...audioInfo.inbound];
  const outbound = [...videoInfo.outbound, ...audioInfo.outbound];
  const inboundSingleBitrate = sumBitrates(
    inbound.filter(inInfo => [inVideoTrackLabel, inAudioTrackLabel].includes(inInfo.trackIdentifier))
  );
  const inboundAllBitrate = sumBitrates(inbound);
  const outboundBitrate = sumBitrates(outbound);

  if (isPublisher) {
    const { frameWidth, frameHeight, framesPerSecond, encoderImplementation } = videoInfo.outbound[0] || {};
    info.width = frameWidth;
    info.height = frameHeight;
    info.frameRate = framesPerSecond;
    info.videoCodec = encoderImplementation;
  } else {
    const {
      frameWidth,
      frameHeight,
      framesPerSecond,
      mimeType
    } = videoInfo.inbound?.filter(inInfo => inInfo.trackIdentifier === inVideoTrackLabel)[0] || {};
    const audioMimeType = audioInfo.inbound.filter(inInfo => inInfo.trackIdentifier === inAudioTrackLabel)
      .map(inInfo => inInfo.mimeType)[0];
    info.width = frameWidth;
    info.height = frameHeight;
    info.frameRate = framesPerSecond;
    // values are "video/H264" and "audio/opus"
    info.videoCodec = [mimeType, audioMimeType]
      .filter(m => !!m)
      .map(m => m.split('/')[1])
      .join('/');
  }

  const inBoundBitratePart = (() => {
    if (isPublisher) {
      return [];
    } else if (audioInfo.inbound.length === 1) {
      return [`IN ${toKbps(inboundSingleBitrate)}`];
    } else {
      return [`IN ${toKbps(inboundSingleBitrate)}/${toKbps(inboundAllBitrate)} [${audioInfo.inbound.length}]`];
    }
  })();
  const outBoundBitratePart = isPublisher ? [`OUT ${toKbps(outboundBitrate)}`] : [];

  info.bitrateString = [...inBoundBitratePart, ...outBoundBitratePart].join(', ');
  return info;
};
