import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CustomizationModel } from '../../models/customization.model';
import { CustomizationService } from '../../services/customization.service';
import { StateService } from '@shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  public customConfig: CustomizationModel;
  private roomName: string | null;

  constructor(
    private http: HttpClient,
    public customizationService: CustomizationService,
    public readonly stateService: StateService,
  ) {
    this.customConfig = customizationService.config;
    this.roomName = this.stateService.roomName;
  }

  getUserCountry() {
    return this.http.get(environment.ipiApi);
  }

  getUserWeather(city: string) {
    return this.http.get(`${environment.weatherApi}?q=${city}&appid=${environment.weatherKey}`);
  }
}
