import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';
import { MediaService } from '@shared/services/media.service';

@Injectable({
  providedIn: 'root'
})

export class PermissionsService {

  constructor(private platformDetectorService: PlatformDetectorService) {}

  /**
   * This functionality is for Checking browser permission without calling native request,
   * we need to always return false for Safari and Firefox,
   * because those browsers don't support checking permission without native request
   */
  public checkPermissions(): Observable<boolean> {

    if (this.platformDetectorService.isSafari() || this.platformDetectorService.isFirefox()) {
      return of(false);
    }

    const micPermission = 'microphone' as PermissionName;
    const camPermission = 'camera' as PermissionName;
    const checkAudioPermission = from(this.checkNavigatorPermissions(micPermission));
    const checkCameraPermission = from(this.checkNavigatorPermissions(camPermission));
    return combineLatest(checkAudioPermission, checkCameraPermission).pipe(
      switchMap((response: any) => {
        const hasPermission = response.filter((item: any) => item.state === 'granted');
        return of(hasPermission.length > 1);
      })
    );
  }

  private checkNavigatorPermissions(name: PermissionName): Promise<PermissionStatus> {
    return navigator.permissions.query({ name });
  }
}
