<div>
  <div class="container">
    <stusan-icon icon="close" class="close" (click)="close()"></stusan-icon>
    <h1>Profile</h1>
    <div class="scrollable">
      <div class="avatar-wrap">
        <div *ngIf="!this.currentUser?.photoURL; else avatar">
          <stusan-toggle-icon
            [label]="'You'"
            [icon]="'menu-profile'"
          >
          </stusan-toggle-icon>
        </div>
        <ng-template #avatar>
          <span class="avatar">
            <img [attr.src]="this.currentUser?.photoURL"
                 class="avatar__pic"
                 referrerpolicy="no-referrer"
            >
          </span>
        </ng-template>
      </div>
      <div class="likes">
        <div class="likes__item">
          <div class="likes__title">Ride on!</div>
          <div class="likes__entry">
            <div class="likes__icon">
              <stusan-icon icon="like"
                           [style.fill]="'#FC0'"
              ></stusan-icon>
            </div>
            <div class="likes__count">
              {{ (this.stateService.likesCount$ | async) || 0 }}
            </div>
          </div>
        </div>
        <div class="likes__item">
          <div class="likes__title">Stars</div>
          <div class="likes__entry">
            <div class="likes__icon">
              <stusan-icon icon="star"
                           [style.fill]="'#FC0'"
              ></stusan-icon>
            </div>
            <div class="likes__count">
              {{ (this.stateService.starsCount$ | async) || 0 }}
            </div>
          </div>
        </div>
      </div>
      <dl class="item">
        <div class="input-text">
          <input type="text" class="input-text__field"
                 placeholder="Type name"
                 [(ngModel)]="displayName"
          >
          <div class="input-text__placeholder">Name</div>
          <stusan-icon icon="edit"
                       class="edit"
                       (click)="updateProfile(displayName)"
          ></stusan-icon>
        </div>
      </dl>
    </div>
  </div>
</div>
