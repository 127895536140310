import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationMessageData } from '@shared/services/notification/models/notification-message-data.model';

@Component({
  selector: 'thevatra-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly notificationMessageData: NotificationMessageData,
    public readonly matSnackBarRef: MatSnackBarRef<NotificationMessageComponent>
  ) {}

  public closeSnackBar(): void {
    if (this.notificationMessageData.isClosable) {
      this.matSnackBarRef.dismiss();
    }
  }
}
