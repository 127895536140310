<ng-container [ngSwitch]="renderMethod">

  <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

  <ng-container *ngSwitchCase="'template'">
    <ng-template #template *ngTemplateOutlet="content; context: context"></ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'component'">
    <ng-container *ngComponentOutlet="!context ? content : template"></ng-container>

  </ng-container>

</ng-container>