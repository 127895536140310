import { NotificationMessageBase } from '@shared/services/notification/classes/base/notification-message.base';
import { NotificationType } from '@shared/services/notification/enums/notification-type';

const INFO_BACKGROUND_COLOR = '#272d2f';

export class NotificationMessage extends NotificationMessageBase {
  constructor(message: string) {
    super(message);
  }

  protected getNotificationType(): NotificationType {
    return NotificationType.info;
  }

  protected getNotificationBackgroundColor(): string {
    return INFO_BACKGROUND_COLOR;
  }
}
