import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CustomizationModel } from '../../../models/customization.model';
import { CustomizationService } from '../../../services/customization.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'stusan-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit {
  public customConfig: CustomizationModel;
  public code: number;
  public message: string;

  constructor(private customizationService: CustomizationService,
              private readonly route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.customConfig = this.customizationService.config;

    if (this.route.snapshot.queryParams.code) {
      this.code = parseInt(this.route.snapshot.queryParams.code, 10);
    }

    if (this.route.snapshot.queryParams.message) {
      this.message = this.route.snapshot.queryParams.message;
    } else {
      this.message = 'Unexpected error happened during communication with the server.';
    }
  }

  handleIcon(code: number): string | null {
    switch (code) {
      case 4200:
        return 'no-join';
      case 4201:
        return 'not-started';
      case 4202:
        return 'ended';
      case 4203:
        return 'not-found';
      default:
        return null;
    }
  }
}
