import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinishComponent } from '@shared/components/finish/finish.component';
import { TokenResolver } from '@shared/resolvers/token.resolver';
import { TokenGuard } from '@shared/guards/token.guard';
import { AuthGuard } from '@shared/guards/auth.guard';
import { environment } from '../environments/environment';
import { ErrorComponent } from '@shared/components/error/error.component';

let loadChildrenModule = (): any => {};
if (environment.signupFlowEnabled) {
  loadChildrenModule = () => import('./stusan/signup/signup.module').then(m => m.SignupModule);
} else {
  loadChildrenModule = () => import('./stusan/login/login.module').then(m => m.LoginModule);
}

const routes: Routes = [
  { path: 'finish', component: FinishComponent },
  { path: 'error', component: ErrorComponent },
  {
    path: '',
    loadChildren: loadChildrenModule,
  },
  {
    path: 'login/:roomName',
    loadChildren: () => import('./stusan/login/login.module').then(m => m.LoginModule)
  },
  {
    path: ':roomName',
    loadChildren: () => import('./stusan/room/room.module').then(m => m.RoomModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':roomName/:token',
    loadChildren: () => import('./stusan/room/room.module').then(m => m.RoomModule),
    resolve: { data: TokenResolver },
    canActivate: [TokenGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
