import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { PopupComponent } from '@shared/services/popup/popup.component';
import { CustomizationService } from './stusan/services/customization.service';
import { RecordingService } from './stusan/services/recording.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { TokenGuard } from '@shared/guards/token.guard';
import { AuthGuard } from '@shared/guards/auth.guard';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export const playerFactory = () => player;
@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    OverlayModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    TokenGuard,
    AuthGuard,
    CustomizationService,
    RecordingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // @todo: turn On if needed
        showDialog: false, // environment.production, // turn on only for production
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
