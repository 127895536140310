import { Component, ChangeDetectionStrategy } from '@angular/core';

import { PopupRef } from '@shared/services/popup/popup.ref';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';

@Component({
  selector: 'thevatra-cant-share-your-screen-popup',
  templateUrl: './cant-share-your-screen-popup.component.html',
  styleUrls: ['./cant-share-your-screen-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CantShareYourScreenPopupComponent {
  constructor(
    private readonly popupRef: PopupRef<CantShareYourScreenPopupComponent>,
    private readonly platformDetectorService: PlatformDetectorService,
  ) { }

  public closePopup(): void {
    this.popupRef.close();
  }

  public get isMacOs(): boolean {
    return this.platformDetectorService.isMac();
  }
}
