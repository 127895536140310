import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'stusan-toggle-icon',
  templateUrl: './toggle-icon.component.html',
  styleUrls: ['./toggle-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleIconComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() state: boolean | null = false;
  @Input() hover: boolean | null = true;
  @Input() labelColor: string | null;

  constructor() { }
}
