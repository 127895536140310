<div class="container"
     [ngClass]="{
        h100: mutedCam,
        'video-play': !mutedCam,
        'talking': talking && !mutedMic,
        'is-own': isOwn && !isScreen,
        'is-screen': ((stateService.isScreenShareActive$ | async) === id) || isScreen,
        'is-own-screen': isOwn && isScreen
      }"
>
  <span class="name-wrapper"
        (click)="$event.stopPropagation()"
  >
    <span class="country">
      <span class="flag-icon flag-icon-{{ country }}"
            [matTooltip]="city + ', ' + countryName"
            [matTooltipPosition]="'above'"
            *ngIf="country"
      ></span>
    </span>
    <span class="name">
      {{ name }}
      <stusan-icon icon="info"
                   class="name-icon"
                   style="width: 30px; height: 30px;"
                   [style.fill]="'#fff'"
      ></stusan-icon>
    </span>
    <span *ngIf="weather"
          class="weather"
          (click)="togglePoints()"
    >
        <stusan-icon icon="weather"
                     class="weather-icon"
                     style="width: 17px; height: 15px;"
        ></stusan-icon>
      <ng-container *ngIf="points">{{ toCelsius(weather) }}°C</ng-container>
      <ng-container *ngIf="!points">{{ toFahrenheit(weather) }}°F</ng-container>
    </span>
    <span class="time">
      {{ getLocalTime(this.timezone) | date:'H:mm' }}
    </span>
  </span>

  <video #video
         autoplay
         playsinline
         muted
         disablepictureinpicture
         autopictureinpicture="false"
         class="video"
         oncontextmenu="return false;"
         [ngClass]="{
            'is-portrait': screenOrientation === false,
            'is-landscape': screenOrientation === true,
            'is-own': isOwn && !isScreen,
            'is-screen': ((stateService.isScreenShareActive$ | async) === id) || isScreen,
            'is-own-screen': isOwn && isScreen
          }"
         [style.display]="mutedCam ? 'none' : ''">
  </video>
  <img [style.display]="mutedCam ? '' : 'none'"
       class="avatar"
       src="/assets/stusan/images/avatar/avatar.{{ stateService.theme }}.{{ layoutName }}.png"
       alt="">
  <div [style.display]="mutedCam ? '' : 'none'" class="avatarChar {{ layoutName }}">{{ name | firstWord }}</div>

  <div class="control-button-outer like"
       *ngIf="uid"
  >
    <thevatra-like [myId]="id" [myLike]="uid"></thevatra-like>
  </div>

  <div [style.display]="mutedMic ? '' : 'none'" class="control-button-outer mic-off">
    <stusan-control-button>
      <stusan-icon icon="mic-off" style="width: 24px; height: 24px;" [style.fill]="'#fff'"></stusan-icon>
    </stusan-control-button>
  </div>

  <div [style.display]="talking && !mutedMic ? '' : 'none'" class="control-button-outer mic-off activity-on">
    <stusan-control-button>
      <thevatra-equalizer></thevatra-equalizer>
    </stusan-control-button>
  </div>

  <div [style.display]="isScreen ? '' : 'none'" class="control-button-outer share-on">
    <stusan-control-button>
      <stusan-icon icon="menu-share-indicator" style="width: 24px; height: 24px;" [style.fill]="'#fff'"></stusan-icon>
    </stusan-control-button>
  </div>

  <div *ngIf="(showMoreMenu$ | async) === false || isOwn"
       class="control-button-outer fullscreen"
       title="Enter fullscreen"
       (click)="fullScreen()">
    <stusan-control-button>
      <stusan-icon icon="screen-full"
                   style="width: 24px; height: 24px;"
                   [style.fill]="'#fff'">
      </stusan-icon>
    </stusan-control-button>
  </div>

  <div *ngIf="isOwn && !isScreen" class="fullscreen-mic-and-cam-controls">
    <div class="control-button-outer microphone-control">
      <stusan-control-button [ariaLabel]="isAudioEnabled ? 'Mute' : 'Unmute'"
                             [tooltipText]="isAudioEnabled ? 'Disable microphone' : 'Enable microphone'"
                             (click)="toggleMic()"
                             [class.wait]="micMuting$ | async">
        <stusan-toggle-icon
          [labelColor]="isAudioEnabled ? 'default' : 'enabled'"
          [icon]="isAudioEnabled ? 'menu-mic-on' : 'menu-mic-off'"
          [hover]="isAudioEnabled"
          [class.activity]="isAudioEnabled && talking"
        ></stusan-toggle-icon>
      </stusan-control-button>
    </div>

    <div class="control-button-outer camera-control">
      <stusan-control-button [ariaLabel]="isVideoEnabled ? 'Stop Cam' : 'Start Cam'"
                             (click)="toggleCam()"
                             [tooltipText]="isVideoEnabled ? 'Disable camera': 'Enable camera'"
                             [class.wait]="cameraMuting$ | async"
      >
        <stusan-toggle-icon
          [labelColor]="isVideoEnabled ? 'default' : 'enabled'"
          [icon]="isVideoEnabled ? 'menu-cam-on' : 'menu-cam-off'"
          [hover]="isVideoEnabled"
        ></stusan-toggle-icon>
      </stusan-control-button>
    </div>
  </div>

  <div *ngIf="(showMoreMenu$ | async) === true && !isOwn"
       class="control-button-outer fullscreen"
       title="More"
       [matMenuTriggerFor]="moreMenu"
       [matMenuTriggerData]="{id: id}">
    <stusan-control-button>
      <stusan-icon icon="more"
                   style="width: 24px; height: 24px;"
                   [style.fill]="'#fff'">
      </stusan-icon>
    </stusan-control-button>
  </div>
  <mat-menu #moreMenu="matMenu"
            yPosition="below"
            xPosition="before"
            class="video-settings">
    <ng-template matMenuContent
                 let-id="id">
      <div (click)="fullScreen()"
           class="video-settings__item video-settings__item--click">
        <stusan-icon icon="screen-full"
                     style="width: 25px; height: 25px;"
                     [style.fill]="'rgba(39, 45, 47, 1)'">
        </stusan-icon>
        Fullscreen
      </div>
      <div (click)="rtdbSubscriptionService.forceTurnMicOff(id)"
           [class.disabled]="mutedMic"
           class="video-settings__item video-settings__item--switch">
        <stusan-icon icon="mic-on"
                     style="width: 25px; height: 25px;"
                     [style.fill]="'rgba(39, 45, 47, 1)'">
        </stusan-icon>
        Turn mic off
      </div>
    </ng-template>
  </mat-menu>

  <div class="control-button-outer network"
       *ngIf="this.platformDetectorService.isDesktop() && (this.platformDetectorService.isChrome() || this.isOwn)"
       (click)="toggleTechInfo($event)"
       title="Connection info">
    <stusan-control-button>
      <stusan-icon icon="connection" style="width: 24px; height: 24px;" [style.fill]="'#fff'"></stusan-icon>
    </stusan-control-button>
  </div>

  <div class="tech-info" *ngIf="showTech">
    <div>
      <span>Resolution: </span>
      <span *ngIf="techData?.width">{{ techData?.width }} x {{ techData?.height }}</span>
    </div>

    <div>
      <span>Frame rate: </span>
      {{ techData?.frameRate ? techData?.frameRate?.toFixed(2) : 'N/A'}}
    </div>

    <div>
      <span>Video codec: </span>
      {{ techData?.videoCodec || 'N/A' }}
    </div>

    <div>
      <span>Bitrate (Kbps): </span> <span> {{ techData?.bitrateString || '...' }} </span>
    </div>
  </div>
</div>
<ng-lottie [options]="options"
           *ngIf="customizationService.config.isLikesEnabled"
           (animationCreated)="animationCreated($event)"
           [styles]="styles"
           [ngClass]="{
            'hidden': (stateService.isAnimationActive$ | async) === false
           }"
></ng-lottie>
<ng-content></ng-content>
