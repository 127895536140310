import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { CaptureContext } from '@sentry/types';
// @ts-ignore
import { environment } from 'src/environments/environment';
import { ConnectionInfoModel } from '@models';
// import { ClientMonitor, createClientMonitor } from '@observertc/client-monitor-js';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  // private static observerMonitor: ClientMonitor | null;

  /* eslint-disable no-bitwise */
  private static generateUUID() { // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    // Time in microseconds since page-load or 0 if unsupported
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  /* eslint-enable no-bitwise */


  static initObserver(roomId: string, janusHandleId: number) {
    if (!environment.observerUrl) {
      console.log('Skip ObserverRTC integration.');
      return;
    }

    // const observerMonitor = this.observerMonitor = createClientMonitor({
    //   sampler: {
    //     roomId,
    //     clientId: this.generateUUID(), // TODO use janusHandleId
    //     userId: '' + janusHandleId,
    //   },
    //   collectingPeriodInMs: 5000,
    //   samplingPeriodInMs: 10000,
    //   sendingPeriodInMs: 10000,
    //   sender: {
    //     format: 'json',
    //     websocket: {
    //       urls: ['' + environment.observerUrl],
    //       maxRetries: 3,
    //     }
    //   }
    //
    // });
    // observerMonitor.events.onSampleCreated(sample => { });
    // observerMonitor.events.onSampleSent(() => { });
  }

  static addPeerConnection(pc: RTCPeerConnection) {
    // if (this.observerMonitor === null) {
    //   return;
    // }
    // this.observerMonitor.collectors.addRTCPeerConnection(pc);
  }

  static addBreadcrumb(category: string, message: string): void {
    Sentry.addBreadcrumb({
      category,
      message,
      level: Sentry.Severity.Info,
    });
  }

  static configureScope(): void {
    Sentry.configureScope(scope => scope.setUser(null));
  }

  static setUser(user: Sentry.User): void {
    Sentry.setUser(user);
  }

  static captureException(exception: any, context?: string | undefined): void {
    const scope = new Sentry.Scope();
    if (context) {
      scope.setTag('message', context);
    }
    Sentry.captureException(exception, scope);
  }

  static captureMessage(message: string, captureContext?: CaptureContext | Sentry.Severity | undefined): void {
    Sentry.captureMessage(`Can\'t create offer. ${JSON.stringify(message)}`, captureContext);
  }

  /**
   * Sends WebRTC bandwidth, latency, packetLoss reports to Sentry.
   *
   * https://docs.sentry.io/platforms/javascript/performance/instrumentation/custom-instrumentation/
   * https://sentry.trembit.com/organizations/trembit/performance/?project=2
   */
  static reportStats(info: ConnectionInfoModel) {
    const transaction = Sentry.startTransaction({ name: 'webrtc' });
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
    const span = transaction.startChild({
      data: info,
      op: 'stats',
      description: 'WebRTC stats.',
    });

    span.finish();
    transaction.finish();
  }
}
