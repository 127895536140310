import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { RealtimeDatabaseService } from '../../services/rtdb.service';

@Injectable({
  providedIn: 'root'
})
export class TokenResolver implements Resolve<any> {
  constructor(private readonly rtdb: RealtimeDatabaseService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    return this.rtdb.init(route.paramMap.get('token') as string);
  }
}
