<div class="logo-wrapper">
  <a *ngIf="customConfig.homeLogo" href="https://trembit.com/" target="_blank">
    <img class="perm-logo" src="{{ customConfig.homeLogo }}">
  </a>
</div>
<div class="error">
  <span class="error__icon"
        [attr.data-icon]="handleIcon(code)"
  ></span>
  <span class="error__message">{{code}}: {{ message }}</span>
</div>
