import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import Janus, { JanusJS } from '../../../../assets/stusan/scripts/janus/janus';
import adapter from 'webrtc-adapter';
import { StateService } from './state.service';
import { environment } from '../../../../environments/environment';
import { JanusErrorDto, JoinRoomDto, JoinRoomResponse, MultiMedia, Publisher, VoiceState, } from '@models';
import { BackgroundEffectsType, VideoType } from '@enums';
import { MediaService } from './media.service';
import { SoundService } from './sound.service';
import { CustomizationService } from '../../services/customization.service';
import { NotificationService } from '@shared/services/notification/notification.service';
import { PopupService } from '@shared/services/popup/popup.service';
import {
  CantShareYourScreenPopupComponent
} from '@shared/components/popups/cant-share-your-screen-popup/cant-share-your-screen-popup.component';
import { SettingsChanges } from '../../models/settings-changes.model';
import { PlatformDetectorService } from '@shared/services/platform-detector/platform-detector.service';
import { AnalyticsService } from '@shared/services/analytics.service';
import * as Sentry from '@sentry/angular';
import { ensureGlobalObject } from '../../utils/ensure-global-object.util';
import { catchError, distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorModel } from '../../models/error.model';
import { RemoteRoom } from '../../models/remote-room';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@shared/services/app.service';
import { RealtimeDatabaseService } from '../../services/rtdb.service';
import { RealtimeDatabaseSubscriptionsService } from '../../services/rtdb-subscriptions.service';
import { VideoEffectsService } from '@shared/services/video-effects/video-effects.service';
import { PlayLeaveSoundState } from '../../enums/play-leave-sound-state';

@Injectable({
  providedIn: 'root',
})
export class JanusService {
  public userIds: number[] = [];

  public simulcast = false;
  private reconnectAttempts = 0;

  protected janus: any; // @ref: JanusInterface
  protected videoRoomPlugin: any; // @ref: JanusJS.PluginHandle
  protected remoteFeedPlugin: any; // @ref: JanusJS.PluginHandle
  protected videoRoomScreenSharePlugin: any; // @ref: JanusJS.PluginHandle;
  protected videoRoomScreenShareSafariPlugin: any; // @ref: JanusJS.PluginHandle;
  private initPromise: Promise<void>;
  private createSessionPromise: Promise<void>;
  private attachVideoRoomPluginPromise: Promise<void>;
  private attachScreenShareForSafariRoomPluginPromise: Promise<void> | null;
  private readonly localUserId: number;
  public localScreenShareId: number;
  public connection$ = new BehaviorSubject<boolean>(false);

  public voiceState$ = new BehaviorSubject<VoiceState>({
    userId: 0,
    talking: false,
  });
  private audioMediaStreamTracks: any[] = [];

  private get userName(): string {
    return this.stateService.userName || 'participant';
  }

  public localStream$ = new BehaviorSubject<MediaStream | null>(null);
  private videosArray: MultiMedia[] = [];
  private subscriptions = {} as any;

  get videos(): MultiMedia[] {
    return this.videosArray;
  }

  set videos(videos: MultiMedia[]) {
    this.videosArray = videos;
    this.videos$.next(videos);
  }

  public videos$: BehaviorSubject<MultiMedia[]> = new BehaviorSubject<MultiMedia[]>([]);
  private creatingSubscription = false;
  private feedStreams: any = {};
  private msgId: number;
  private remoteTracks: any = {};
  private subStreams: any = {};
  public queryParams: { [key: string]: string } = {};
  private readonly isSafariBrowser: boolean;

  constructor(
    public readonly appService: AppService,
    private readonly notificationService: NotificationService,
    private readonly popupService: PopupService,
    private stateService: StateService,
    private customizationService: CustomizationService,
    private soundService: SoundService,
    private mediaService: MediaService,
    private platform: Platform,
    private platformDetectorService: PlatformDetectorService,
    private readonly rtdb: RealtimeDatabaseService,
    private readonly rtdbSubscriptionsService: RealtimeDatabaseSubscriptionsService,
    private http: HttpClient,
    private readonly route: ActivatedRoute,
    private readonly videoEffectsService: VideoEffectsService
  ) {
    this.isSafariBrowser = this.platformDetectorService.isBrowserSafari();
    this.localUserId = parseInt(this.stateService.userId, 10);
    console.log('loaded localUserId', this.stateService.userId);
    if (this.localUserId <= 0 || isNaN(this.localUserId)) {
      throw new Error('Wrong state with this.localUserId ' + this.localUserId);
    }
    // TODO RTDB do we still need this?
    // this.mutes.subscribe(mute => {
    //   if (mute) {
    //     if (mute.type === 'video') {
    //       this.stateService.isCamEnabled = mute.on;
    //     }
    //     if (mute.type === 'audio') {
    //       this.stateService.isMicEnabled = mute.on;
    //     }
    //   }
    // });
  }

  updateUsersCount() {
    ensureGlobalObject('APP.conference').membersCount = this.userIds.length;
  }

  public getLocalUserId() {
    return this.localUserId;
  }

  private init(): Promise<void> {
    if (!this.initPromise) {
      this.initPromise = new Promise((resolve, reject) => {
        Janus.init({
          //debug: true,
          dependencies: Janus.useDefaultDependencies({ adapter }),
          callback: () => {
            resolve();
          },
        });
      });
    }

    return this.initPromise;
  }

  private async createSession(): Promise<void> {
    await this.init();

    if (!Janus.isWebrtcSupported()) {
      throw new Error('No WebRTC support');
    }

    if (!this.createSessionPromise) {
      this.createSessionPromise = new Promise((resolve, reject) => {
        this.janus = new Janus({
          server: this.appService.janusUrl as string,
          iceServers: [
            { urls: 'stun:stun1.l.google.com:19302' },
            {
              urls: 'turn:coturn.trembit.com:443?transport=tcp',
              username: 'stusan',
              credential: 'stusanfd8s0gxy9U59',
            },
          ],
          success: () => {
            // The session was successfully created and is ready to be used
            resolve();
          },
          error: error => {
            // The session was NOT successfully created
            console.error('[session] Can\'t create session: ', error, ' sessionId: ', this.janus.getSessionId());
            this.notificationService.showError('Problem establishing connection to media server.');
            reject(error);
          },
          destroyed: () => {
            // The session was destroyed and can't be used any more.
            // TODO: redirect to login page
          },
        });
      });
    }

    return this.createSessionPromise;
  }

  public reconnectSession(): void {
    if (this.reconnectAttempts++ > 5) {
      this.stateService.clearState();
      this.finishCall();
      window.location.reload(); // @dev: this is eq to logout

      return;
    }
    setTimeout(() => {
      if (this.janus?.reconnect) {
        this.janus.reconnect({
          success: () => {
            this.reconnectAttempts = 0;
            console.log('Session successfully reclaimed:', this.janus.getSessionId());
            // this.soundService.playJoin();
            this.stateService.playLeaveSound = PlayLeaveSoundState.default;
            this.notificationService.showInfo('Successfully rejoined to the media server.');
          },
          error: (error: any) => {
            console.warn('[Session] Failed to reconnect[' + this.reconnectAttempts + '] error: ', error);
            if (error.indexOf('No such session') !== -1) {
              console.log('[Js] reconnect with creating new session.');
              location.reload();
            }
            this.reconnectSession();
          },
        });
      }
    }, 2000);
  }

  private async attachVideoRoomPlugin(roomPin?: string): Promise<void> {
    await this.createSession();

    if (!this.attachVideoRoomPluginPromise) {
      this.attachVideoRoomPluginPromise = new Promise((resolve, reject) => {
        // Using direct sentry access as too tricky to use the wrapper
        const transaction = Sentry.startTransaction({ name: 'webrtc-connect' });
        Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));
        const span = transaction.startChild({
          data: {},
          op: 'ice',
          description: 'Ice',
        });

        // Video room https://janus.conf.meetecho.com/videoroomtest.js
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: (pluginHandle: any) => {
            // JanusJS.PluginHandle
            this.videoRoomPlugin = pluginHandle;
            console.log('attach--videoRoomPlugin--');

            this.handleBackgroundEffectChanges();

            resolve();
          },
          error: (error: string) => {
            console.error('Can\'t attach video room plugin to Janus', error);
            reject(error);
          },
          consentDialog: (on: boolean) => {
            // We can show here popup, explaining user what he should allow audio & video in the browser
          },
          iceState: (state: string) => {
            if (state === 'failed') {
              // used when connection was failed
              this.notificationService.showError('Unable to reach the media server. Please check your network or report this case.',
                30000000
              );

              // TODO SR/20220511 retry connect attempt
            }

            if (state === 'disconnected') {
              this.soundService.playLeave();
              this.stateService.playLeaveSound = PlayLeaveSoundState.notPlay; // mark for login page to not play Leave sound
              this.reconnectSession();

              this.notificationService.showError(
                'Lost connection to the media server.'
              );
            }

            if (state === 'connected') {
              AnalyticsService.addPeerConnection(
                this.videoRoomPlugin.webrtcStuff.pc
              );
            }

            AnalyticsService.addBreadcrumb(
              'janus',
              `ICE state changed to ${state}`
            );
            console.log('[P] ICE state changed to ' + state);
          },
          mediaState: (medium: 'audio' | 'video', on: boolean) => {
            console.log('useful? 3 ', medium, on);
            // console.log('[P] Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
          },
          webrtcState: (on: boolean) => {
            this.connection$.next(on);
            AnalyticsService.addBreadcrumb('janus', `WebRTC state changed to ${on}`);

            if (on) {
              span.setStatus('ok');
            } else {
              span.setStatus('unknown_error');
            }
            span.finish();
            transaction.finish();
          },
          ondataopen: (data: string) => {
            console.log('dc [Publisher] The DataChannel is available! data: ', data);
          },
          ondata: (data: any) => {
            // console.log('[P] data: ', data);
          },
          slowLink: (...agrs: any[]) => {
            console.log('slowLink', ...agrs);
            this.notificationService.showInfo('Slow connectivity to the server.', 20000);
          },
          onmessage: (msg: any, jsep: any) => {
            // console.log('onmessage ' + JSON.stringify(msg));
            const handleId = msg.id;

            // show voice activity
            const changeTalking = (userId: number, talking: boolean) =>
              this.voiceState$.next({ userId, talking });
            if (msg.videoroom === 'talking') {
              changeTalking(handleId, true);
            }
            if (msg.videoroom === 'stopped-talking') {
              changeTalking(handleId, false);
            }

            // show join notification
            if (
              msg.videoroom === 'event' && msg.joining &&
              this.userIds.find(userId => userId !== msg.id) &&
              !this.appService.isScreen(msg.joining.display) &&
              !msg.joining.display.includes('Jibri')
            ) {
              this.notificationService.showToastr(`${msg.joining.display} has joined.`);
            }
            const event = msg.videoroom;
            // console.log('[P] msg:  ', msg);
            if (event) {
              if (event === 'joined') {
                // local user joined
                this.msgId = msg.id;
                this.rtdbSubscriptionsService.initAllSubscriptions(msg.id);
                this.rtdbSubscriptionsService.addUserInfo(msg.id, this.userName, true);
                if (msg.id) {
                  const existedUserId = this.userIds.find(
                    userId => userId !== msg.id
                  );
                  if (!existedUserId) {
                    this.userIds.push(msg.id);
                    this.updateUsersCount();

                    if (this.stateService.isCamBanned) {
                      this.addCurrentUserFakeVideo();
                    }
                  }
                }
                AnalyticsService.setUser({
                  id: msg.id,
                  username: this.userName,
                });

                // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any

                // User might have changed setting on login screen, use them
                const settings: any = {}; // TODO: any

                if (this.stateService.audioDeviceId$.value) {
                  settings.audioDeviceId = this.stateService.audioDeviceId$.value;
                }
                if (this.stateService.videoDeviceId$.value) {
                  settings.videoDeviceId = this.stateService.videoDeviceId$.value;
                }

                this.createOffer(settings);

                // Any new feed to attach to
                const publishers = msg.publishers;
                if (publishers) {
                  // console.log('!!! new publishers 1 !!!', publishers);
                  const list = msg.publishers;
                  Janus.debug('Got a list of available publishers/feeds:', list);
                  this.registerPublishers(publishers, roomPin);
                }
              } else if (event === 'destroyed') {
                // The room has been destroyed
              } else if (event === 'event') {
                if (msg.streams) {
                  const streams = msg.streams;
                  for (const i in streams) {
                    const stream = streams[i];
                    stream.id = this.msgId;
                    stream.display = this.userName;
                  }
                  this.feedStreams[this.msgId] = {
                    id: this.msgId,
                    display: this.userName,
                    streams,
                  };
                } else if (msg.publishers) {
                  const publishers = msg.publishers;
                  // this.soundService.playJoin();
                  const list = msg.publishers;
                  Janus.debug(
                    'Got a list of available publishers/feeds:',
                    list
                  );
                  this.registerPublishers(publishers, roomPin);
                } else if (msg.leaving) {
                  // One of the publishers has gone away
                  if (msg.unpublished && msg.unpublished === 'ok') {
                    // That's us
                    this.videoRoomPlugin.hangup();
                    return;
                  }

                  const leftParticipantId = msg.leaving;
                  const leftParticipant = this.videos.find(({ video }: MultiMedia) => video?.id === leftParticipantId);
                  if (leftParticipant) {
                    const audioTracks = leftParticipant.audio?.stream?.getTracks() || [];
                    audioTracks.forEach((track: any) => {
                      if (track) {
                        track.stop();
                      }
                    });
                    const videoTracks = leftParticipant.video?.stream.getTracks() || [];
                    videoTracks.forEach((track: any) => {
                      if (track) {
                        track.stop();
                      }
                    });
                    this.videos = this.videos.filter(({ video }: MultiMedia) => video.id !== leftParticipantId);

                    this.rtdbSubscriptionsService.removeUserInfo(leftParticipantId);

                    this.unsubscribeFrom(leftParticipantId);
                    this.userIds = this.userIds.filter(id => id !== msg.leaving);
                    this.updateUsersCount();
                    // this.soundService.playLeave(); // Somebody leaves
                  }
                } else if (msg.error) {
                  const errorCodesMap: { [key: number]: string } = {};
                  errorCodesMap[426] = 'This is a no such room';
                  const errorHumanDescription = errorCodesMap[msg.error_code] || 'Janus error';
                  const errorMessage = `Error code:${msg.error_code}. ${errorHumanDescription}. ${msg.error}`;

                  if (434 === msg.error_code || 425 === msg.error_code) {
                    // console.info(`[info]  ${msg.error}`);
                    this.notificationService.showError(`Problem reported by server. Error ${msg.error}.`);
                  } else {
                    console.error('[event] Error', errorMessage);
                    this.notificationService.showError(`Problem reported by server. ${errorMessage}.`);
                    // alert(errorMessage);
                  }
                  AnalyticsService.captureMessage(`Problem reported by server. ${errorMessage}.`);
                }
              }
            }

            if (jsep) {
              // Without this video will be lost in 10 seconds
              this.videoRoomPlugin.handleRemoteJsep({ jsep });
              // Check if any of the media we wanted to publish has been rejected (e.g., wrong or unsupported codec)
            }
          },
          onlocaltrack: (mediaStreamTrack: MediaStreamTrack) => {
            console.log(`[Publisher] onlocaltrack ${mediaStreamTrack.kind}`, mediaStreamTrack);
            // TODO REPLACE VIDEO VARIANT
            /*
              const label = mediaStreamTrack.label;
              if ((!label && this.isSafariDesktop) || (label && label.includes('screen'))) {
                mediaStreamTrack.onended = () => {
                  this.stopShareScreen();
                  this.stateService.isScreenShareEnabled$.next(false);
                };
              }*/

            // OLD implementation
            // let videoStream: MediaStream; // : MediaStream
            // const localStream = this.localStream$.value;
            // if (localStream) {
            //   const foundTrackByLabel = localStream.getTracks()
            //     .find(track => track.label === mediaStreamTrack.label || track.readyState === 'ended');
            //   const actualMediaStreamTracks = localStream.getTracks()
            //     .filter(track => track.label !== foundTrackByLabel?.label || track.readyState !== 'ended');
            //
            //   videoStream = new MediaStream(actualMediaStreamTracks);
            // } else {
            //   videoStream = new MediaStream();
            // }

            // if we got video track, then need to preserve all audio ones and drop any existing video tracks
            const oppositeTracks = this.localStream$.value?.getTracks()
              .filter(t => t.kind !== mediaStreamTrack.kind) || [];
            const videoStream = new MediaStream(oppositeTracks);

            if (mediaStreamTrack.kind === 'audio') {
              // mediaStreamTrack.enabled = this.stateService.audioEnabled;
              if (this.stateService.audioEnabled) {
                this.videoRoomPlugin.unmuteAudio();
              } else {
                this.videoRoomPlugin.muteAudio();
              }
              console.log('useful? 1');
              // this.mutes.next({ type: 'audio', on: this.stateService.audioEnabled });
            }

            if (mediaStreamTrack.kind === 'video') {
              mediaStreamTrack.enabled = this.stateService.videoEnabled;

              if (!this.stateService.videoEnabled) {
                mediaStreamTrack.stop();
              }
              console.log('useful? 2');
              // this.mutes.next({ type: 'video', on: this.stateService.videoEnabled });
            }

            // TODO StanR do not propagate changes if the state was not changed
            // that must be the case when camera changed via settings
            this.rtdbSubscriptionsService.sendLocalMediaState(this.msgId);

            videoStream.addTrack(mediaStreamTrack);

            const localVideo = this.videos.find(({ video }: MultiMedia) => !video.remote && video.type !== VideoType.screen);
            const localUserInfo = this.rtdbSubscriptionsService.getUserInfo(this.msgId, true);

            if (localVideo) {
              this.videos = this.videos.map(
                (multiMedia: MultiMedia) => (multiMedia.video.remote || multiMedia.video.type === 'screen')
                  ? multiMedia
                  : {
                    video: {
                      stream: videoStream,
                      pluginHandle: this.videoRoomPlugin,
                      id: this.localUserId,
                      name: this.stateService.userName || 'You',
                      remote: false,
                      type: VideoType.video,
                      mutedMic: !localUserInfo.audio,
                      mutedCam: !localUserInfo.video,
                    },
                  }
              );
            } else {
              this.videos = [
                ...this.videos,
                {
                  video: {
                    stream: videoStream,
                    pluginHandle: this.videoRoomPlugin,
                    id: this.localUserId,
                    name: this.stateService.userName || 'You',
                    remote: false,
                    type: VideoType.video,
                  },
                },
              ];
            }

            this.localStream$.next(videoStream);
          },
          oncleanup: () => {
            // PeerConnection with the plugin closed, clean the UI. The plugin handle is still valid so we can create a new one
            this.localStream$.next(null);
            delete this.feedStreams[this.msgId];
          },
          detached: () => {
            // console.log('Connection with the plugin closed, get rid of its features. The plugin handle is not valid anymore');
          },
        });
      });
    }

    return this.attachVideoRoomPluginPromise;
  }

  private sendJoinRequestForScreenShare(handlePlugin: any, isZwift: boolean) {
    this.localScreenShareId = this.getRandomId();
    console.log('sendJoinRequestForScreenShare ', this.localScreenShareId);

    handlePlugin.send({
      message: {
        request: 'join',
        room: this.stateService.roomId,
        ptype: 'publisher',
        display: this.userName + (isZwift ? ' zwift screen' : ' screen'),
        id: this.localScreenShareId,
      },
    });
  }

  private handleOnLocalTrackScreenShare(
    handlePlugin: any,
    mediaStreamTrack: MediaStreamTrack,
    isZwift: boolean = false
  ) {
    const videoStream = new MediaStream();

    videoStream.addTrack(mediaStreamTrack);

    this.videos = [
      ...this.videos,
      {
        video: {
          stream: videoStream,
          pluginHandle: handlePlugin,
          id: this.localScreenShareId,
          name: this.userName + (isZwift ? ' zwift screen' : ' screen'),
          remote: false,
          type: VideoType.screen,
        },
      },
    ];

    this.rtdbSubscriptionsService.sendScreenShareState(this.localScreenShareId);

    // User stop screen sharing
    mediaStreamTrack.onended = () => {
      console.log('Detected screen share stop from track 1.');
      this.stateService.isScreenShareEnabled$.next(false);
      handlePlugin.detach(null);
      this.rtdbSubscriptionsService.sendScreenShareState(null);
    };
  }

  private async attachScreenShareForSafariRoomPlugin(isZwift: boolean = false): Promise<void> {
    await this.createSession();

    if (!this.attachScreenShareForSafariRoomPluginPromise) {
      this.attachScreenShareForSafariRoomPluginPromise = new Promise((resolve, reject) => {
        this.janus.attach({
          plugin: 'janus.plugin.videoroom',
          success: (pluginHandle: JanusJS.PluginHandle) => {
            this.videoRoomScreenShareSafariPlugin = pluginHandle;
            resolve();
            console.log('Attached -videoRoomScreenShareSafariPlugin-');
            this.sendJoinRequestForScreenShare(this.videoRoomScreenShareSafariPlugin, isZwift);
          },
          error: (error: string) => {
            console.error('Can\'t attach video room plugin to Janus for screen share, error: ', error);
            this.notificationService.showError('Problem happened in media communication. Can\'t publish screen.');
          },
          consentDialog: (on: boolean) => {
          },
          iceState: (state: string) => {
          },
          mediaState: (medium: 'audio' | 'video', on: boolean) => {
          },
          webrtcState: (on: boolean) => {
          },
          onmessage: (msg: any, jsep: any) => {
            // TODO: anys
            if (jsep) {
              this.videoRoomScreenShareSafariPlugin.handleRemoteJsep({ jsep });
            }
          },
          onlocaltrack: (mediaStreamTrack: MediaStreamTrack) => {
            console.log('[ScreenShare] onlocaltrack', mediaStreamTrack);
            this.handleOnLocalTrackScreenShare(this.videoRoomScreenShareSafariPlugin, mediaStreamTrack, isZwift);
          },
          oncleanup: () => {
          },
          detached: () => {
          },
        });
      }
      );
    }

    return this.attachScreenShareForSafariRoomPluginPromise;
  }

  private registerPublishers(publishers: Publisher[], roomPin?: string) {
    // console.log('registerPublishers', publishers);
    const sources = [];
    for (const f in publishers) {
      if (publishers[f].dummy) {
        continue;
      }
      const id = publishers[f].id;
      const display = publishers[f].display;
      const streams = publishers[f].streams;

      this.rtdbSubscriptionsService.addUserInfo(id, display);

      for (const i in streams) {
        const stream = streams[i];
        stream.id = id;
        stream.display = display;
      }
      Janus.debug('  >> [' + id + '] ' + display + ':', streams);
      this.feedStreams[id] = {
        id,
        display,
        streams
      };
      sources.push(streams);
    }
    if (sources.length > 0) {
      this.addPublishers(publishers, roomPin, sources);
    }
  }

  private addPublishers(publishers: Publisher[], pin?: string, sources?: any) {
    for (const i in publishers) {
      const id = publishers[i].id;
      this.userIds.push(id);
      this.userIds = this.userIds.filter(
        (userId, index) => this.userIds.indexOf(userId) === index
      );
      this.updateUsersCount();
    }

    if (this.creatingSubscription) {
      // Still working on the handle, send this request later when it's ready
      setTimeout(() => {
        this.addPublishers(publishers, pin, sources);
      }, 500);
      return;
    }
    if (this.remoteFeedPlugin) {
      const subscription = [];

      for (const i in publishers) {
        const id = publishers[i].id;
        const display = publishers[i].display;
        const audio = publishers[i].audio_codec;
        const video = publishers[i].video_codec;
        const publisher = publishers[i];
        const streams = publisher.streams;

        for (const index in streams) {
          const stream = streams[index];
          stream.id = id;
          stream.display = display;
          stream.audio = audio;
          stream.video = video;

          // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
          if (
            stream.type === 'video' &&
            Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
            (stream.codec === 'vp9' || (stream.codec === 'vp8' && !Janus.safariVp8))
          ) {
            console.warn('Publisher is using ' + stream.codec.toUpperCase + ', ' +
              'but Safari doesnt support it: disabling video stream #' + stream.mindex);
            continue;
          }

          if (stream.disabled) {
            Janus.log('Disabled stream:', stream);
            // TODO Skipping for now, we should unsubscribe
            continue;
          }
          if (
            this.subscriptions[stream.id] &&
            this.subscriptions[stream.id][stream.mid]
          ) {
            Janus.log('Already subscribed to stream, skipping:', stream);
            continue;
          }

          subscription.push({ feed: stream.id, mid: stream.mid });

          if (!this.subscriptions[stream.id]) {
            this.subscriptions[stream.id] = {};
          }

          this.subscriptions[stream.id][stream.mid] = true;
        }
      }

      if (subscription.length === 0) {
        // Nothing to do
        return;
      }
      this.remoteFeedPlugin.send({
        message: { request: 'subscribe', streams: subscription },
      });
      console.log(subscription, '------subscription-----Don`t attach!!!!!');
      return;
    }
    this.creatingSubscription = true;
    this.newRemoteFeed(sources, pin);
  }

  public getRoomJoinInfo(roomName: string): Observable<RemoteRoom> {
    let headers = new HttpHeaders();
    if (this.route.snapshot.queryParams.apiKey) {
      this.queryParams.apiKey = this.route.snapshot.queryParams.apiKey;
      headers = headers.set('x-api-key', this.queryParams.apiKey);
    }

    return this.http
      .post(
        `${environment.apiUrl}/api/v2/webinars/${roomName}/join`,
        {},
        { headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(this.handleError<any>('join'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: ErrorModel): Observable<T> => throwError(error.error);
  }

  public async joinRoom(roomId: any, userName: string, pin?: string, doubleJoinForSafari = false):
    Promise<JoinRoomResponse> {
    try {
      if (doubleJoinForSafari) {
        await this.attachScreenShareForSafariRoomPlugin(this.stateService.isZwiftShareEnabled);
      } else {
        await this.attachVideoRoomPlugin(pin);
      }
    } catch (error) {
      console.log('[Join] attachVideoRoomPlugin error: ', error);
      this.notificationService.showError('Can\'t join the call. Plugin is not attached.');
      return Promise.reject();
    }
    const localUserId = doubleJoinForSafari ? this.localUserId + 1 : this.localUserId;

    if (doubleJoinForSafari) {
      return await new Promise<JoinRoomResponse>((resolve, reject) => {
        this.videoRoomScreenShareSafariPlugin.send({
          message: {
            request: 'join',
            ptype: 'publisher',
            room: roomId, // @dev: roomId to set
            display: userName,
            id: environment.janus.stringRoomIds ? localUserId.toString() : localUserId,
            keyframe: true,
            pin,
          },
          success: (data: JoinRoomDto) => {
            resolve(data);
          },
          error: (error: JanusErrorDto) => {
            console.error('Can\'t join room', error);
            reject(error);
          },
        });
      });
    }

    const result: JoinRoomResponse = await new Promise<JoinRoomResponse>((resolve, reject) => {
      this.videoRoomPlugin.send({
        message: {
          request: 'join',
          ptype: 'publisher',
          room: roomId, // @dev: roomId to set
          display: userName,
          id: environment.janus.stringRoomIds ? localUserId.toString() : localUserId,
          keyframe: true,
          pin,
        },
        success: (data: JoinRoomDto) => {
          AnalyticsService.addBreadcrumb('janus', 'Joined janus room.');
          this.stateService.isJoinedRoom = true;
          AnalyticsService.initObserver(this.stateService.roomIdString, this.videoRoomPlugin.id);
          resolve(data);
        },
        error: (error: JanusErrorDto) => {
          console.error('Can\'t join room', error);
          this.stateService.isJoinedRoom = false;
          this.notificationService.showError('Can\'t join the call. Joining the call failed.');
          reject(error);
        }
      });
    });

    return result;
  }

  public sendVideo(send = true): any {
    const media = !!send ? ({ addVideo: true, video: true } as any) : { removeVideo: true };

    if (!!media.addVideo) {
      media.video = this.stateService.videoDeviceId ? this.mediaService.getSettingsForVideoOffer(null) : true;
    }
    if (!this.stateService.isMicEnabled) {
      media.removeAudio = true;
    } else {
      media.audio = true;
    }
    // ---- media Stream
    const stream = this.localStream$.value; // : MediaStream

    if (media.removeVideo && !media.addVideo) {
      const videoTrack = (stream as MediaStream).getVideoTracks()[0];

      if (videoTrack) {
        videoTrack.enabled = false;
      }

      this.stateService.videoEnabled = false;
      this.rtdbSubscriptionsService.sendLocalMediaState(this.msgId);
      return;
    } else if (media.addVideo) {
      const videoTrack = stream ? (stream as MediaStream).getVideoTracks()[0] : null;
      this.stateService.videoEnabled = true;
      if (videoTrack) {
        videoTrack.enabled = true;
      }
    }

    this.rtdbSubscriptionsService.sendLocalMediaState(this.msgId);
    // ---- EOF media Stream
  }

  /**
   * Replaces local user streams in active WebRTC connection.
   *
   * Scenario 1.
   * Device changed via settings. Then we don't change tracks number, but replace them.
   * Note: that microphone can be muted by user, and at the same time replaced.
   * In this case we mute audio track in 'onlocaltrack' callback from Janus.
   *
   * @see onlocaltrack: (mediaStreamTrack: MediaStreamTrack)
   *
   * Scenario 2.
   * User mutes or unmutes camera. Then we either add or remove video track.
   * The new offer will be send to Janus.
   *
   * Notes:
   * Possible improvement is to reuse stream from Settings and pass it to Janus as is.
   * That will avoid extra getUserMedia invocation.
   *
   * @link https://janus.conf.meetecho.com/docs/JS.html
   */
  public replaceStream(
    changes: SettingsChanges,
    isFromSettings: boolean
  ): any {
    const isMobilePlatform = this.platformDetectorService.isMobile();
    const {
      audioDeviceId,
      videoDeviceId,
    } = changes;

    // flag for Janus
    const videoSend = true; //this.stateService.videoEnabled;

    if (!isFromSettings && this.stateService.videoEnabled) {
      this.videoRoomPlugin.unmuteVideo();
      this.localStream$.value?.getVideoTracks().forEach(videoTrack => videoTrack.enabled = true);
    } else if (!isFromSettings && !this.stateService.videoEnabled) {
      this.mediaService.turnOffWebCamLight(this.localStream$.value);
    }

    const video: any = {
      type: 'video',
      capture: { deviceId: { ideal: videoDeviceId } },
      // remove below to disable video
      replace: true,
      recv: true,
    };

    // TODO handle scenario if user doesn't have camera

    // UNCOMMENT to not send video stream
    // if (isFromSettings) {
    //   // switch cam (or mic change)
    //   video.replace = true;
    //   video.recv = true;
    // } else {
    //   if (this.stateService.videoEnabled) {
    //     // unmute cam
    //     // video.replace = true;
    //     video.add = true;
    //     video.recv = true;
    //   } else {
    //     // mute cam
    //     video.remove = true;
    //     delete video.capture;
    //   }
    // }

    const audio = {
      type: 'audio',
      replace: true,
      capture: { deviceId: { ideal: audioDeviceId } },
      recv: true,
    };
    const tracks = [video, audio];
    console.log(`replace video from settings:${isFromSettings}`, changes, tracks);

    this.videoRoomPlugin.createOffer({
      tracks,
      customizeSdp: (jsep: any) =>
        // If DTX is enabled, munge the SDP
        jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;usedtx=1')
      ,
      success: (jsep: any) => {
        this.videoRoomPlugin.send({
          message: {
            request: 'configure',
            video: videoSend,
            audio: true,
            bitrate: this.customizationService.config.bitrate,
          },
          jsep
        });
      },
      error: (error: any) => {
        console.error('[replaceVideo] error', error);
        this.notificationService.showError('Problem applying your device media. You might need to reload page.');
      },
    });
  }

  public sendAudio(value: boolean): any {
    this.stateService.audioEnabled = value;
    if (value) {
      this.videoRoomPlugin.unmuteAudio();
    } else {
      this.videoRoomPlugin.muteAudio();
    }

    this.rtdbSubscriptionsService.sendLocalMediaState(this.msgId);
  }

  /**
   * Sending initial offer to Janus. Either for camera+mic or screenshare.
   */
  public async createOffer(parameters: any, stream?: MediaStream): Promise<void> {
    // TODO: any

    // Wait until video room plugin will be available
    await this.attachVideoRoomPluginPromise;

    let pluginHandler = parameters.shareScreen ? this.videoRoomScreenSharePlugin : this.videoRoomPlugin;
    pluginHandler = parameters.safariScreenShare ? this.videoRoomScreenShareSafariPlugin : pluginHandler;
    const media: any = parameters.shareScreen
      ?
      {
        // TODO: any
        video: 'screen', // Enable screen share
        audioSend: false, // Do or do not send audio
        videoRecv: false, // Do or do not receive video
      }
      :
      {
        audioRecv: true, // Do or do not receive audio
        videoRecv: true, // Do or do not receive video
        // force to true
        audioSend: true, //  this.stateService.isMicEnabled, // Do or do not send audio
        // force to true and later updated with stateService.videoEnabled
        videoSend: true, // this.stateService.isCamEnabled, // Do or do not send video
        data: false,
      };

    if (parameters.audioDeviceId) {
      media.audio = { deviceId: { ideal: parameters.audioDeviceId } };
    }

    if (parameters.videoDeviceId) {
      media.video = { deviceId: { ideal: parameters.videoDeviceId } };
    }

    if (parameters.replaceAudio) {
      // This is only needed in case of a renegotiation
      media.replaceAudio = parameters.replaceAudio;
    }

    if (parameters.replaceVideo) {
      // This is only needed in case of a renegotiation
      media.replaceVideo = parameters.replaceVideo;
    }

    const isCameraForbidden = this.stateService.isRecordingBot || this.stateService.isCamBanned;
    const sendVideo = !isCameraForbidden;// && (parameters.shareScreen || this.stateService.videoEnabled);

    if (!parameters.shareScreen) {
      if (sendVideo) {
        media.video = {
          ...this.stateService.constraints.video,
          deviceId: this.stateService.videoDeviceId
        };
        media.videoSend = true;
      } else {
        media.video = false;
        delete media.videoSend;
      }
      media.audio = {
        ...this.stateService.constraints.audio,
        deviceId: this.stateService.audioDeviceId
      };
    }

    if (isCameraForbidden) {
      // user Blocked access to cam, or is a recording bot. we disable sending video and audio
      media.audioSend = false;
      media.videoSend = false;
    }

    let simulcast = null;
    if (this.stateService.simulcastEnabled) {
      if (!this.platform.FIREFOX) {
        simulcast = { simulcast: true };
      } else {
        //  if (this.platform.BLINK)
        simulcast = { simulcast2: true };
      }
    }

    if (this.stateService.isRecordingBot) {
      media.video = false;
      media.audio = true;
      media.audioSend = true;
      media.audioRecv = true;
      media.videoRecv = true;
    }

    console.log('[CreateOffer] publisher: ', media);
    pluginHandler.createOffer({
      media,
      // ...simulcast,
      customizeSdp: (jsep: any) =>
        // If DTX is enabled, munge the SDP
        jsep.sdp = jsep.sdp.replace('useinbandfec=1', 'useinbandfec=1;usedtx=1')
      ,
      success: (jsep: JanusJS.JSEP) => {
        AnalyticsService.addBreadcrumb('janus', 'SDP offer created.');

        const bitrateValue = parameters.shareScreen ?
          this.customizationService.config.screenBitrate : this.customizationService.config.bitrate;

        pluginHandler.send({
          message: {
            request: 'configure',
            // Both forced to true - later handled with stateService flags
            audio: !this.stateService.isRecordingBot, // parameters.shareScreen ? true : this.stateService.isMicEnabled,
            video: sendVideo,
            bitrate: bitrateValue,
          },
          jsep,
        });
        // @here: enabled = false
        // if (!this.stateService.videoEnabled) {
        //   this.videoEnabledFalse();
        // }
        // if (!this.stateService.audioEnabled) {
        //   this.audioEnabledFalse();
        // }
      },
      error: (error: JanusErrorDto | any) => {
        console.log(
          `[js] Can\'t create offer. Description: ${error.name}. ${error.message}, ` +
          `isScreen: ${parameters.shareScreen}`,
          error
        );
        // User didn't give a permission to share his screen
        if (parameters.shareScreen) {
          this.stateService.isScreenShareClicked = false;
          this.stateService.isScreenShareEnabled$.next(false);

          if (error.message === 'Permission denied by system') {
            console.log('Notify user use about screen share issue.');
            this.popupService.dialog(CantShareYourScreenPopupComponent);
          }
        } else {
          this.notificationService.showError('Problem happened in media communication. Please report.');

          if (error.message && error.message.indexOf('Concurrent mic process limit') > -1) {
            // 20220203/STANR: Firefox only rare issue STUS2-242
            // https://stackoverflow.com/questions/59068146/navigator-mediadevices-getusermedia-api-rejecting-with-error-notreadableerror
            console.error('Reload to solve: Concurrent mic process limit.');
            setTimeout(() => window.location.reload(), 3000);
          } else if (error.name === 'OverconstrainedError') {
            // reset constraints and reload
            console.error('Reload to solve: OverconstrainedError.');
            this.stateService.clearAllState();
            this.notificationService.showError(
              'Problem happened in media communication. Please try to refresh the page.',
              30
            );
            setTimeout(() => window.location.reload(), 3000);
          } else if (error.name === 'NotReadableError') {
            // TODO 20220208/STANR show popup instead
            this.notificationService.showError('Can\'t access your web camera. Please reboot.', 30000);
          } else {
            const msg = 'Reporting can\'t create offer with unhandled error.';
            console.log(msg, error);
            AnalyticsService.captureException(error, msg);
            AnalyticsService.captureMessage(`Can\'t create offer. ${JSON.stringify(error)}`);
            this.notificationService.showError('Problem happened in media communication. Please try to refresh the page.', 30);
          }
        }
      },
    });
  }

  // private videoEnabledFalse() {
  //   const s = this.localStream$.value; // : MediaStream
  //   if (!s) {
  //     return;
  //   }
  //   const videoTrack = (s as MediaStream).getVideoTracks()[0];
  //   if (videoTrack) {
  //     videoTrack.enabled = false;
  //   }
  //   this.stateService.videoEnabled = false;
  //   this.mediaService.turnOffWebCamLight(this.localStream$.value);
  // }

  // private audioEnabledFalse() {
  //   const s = this.localStream$.value; // : MediaStream
  //   if (!s) {
  //     return;
  //   }
  //   const audioTrack = (s as MediaStream).getAudioTracks()[0];
  //   if (audioTrack) {
  //     audioTrack.enabled = false;
  //   }
  //   audioTrack.enabled = false;
  //   this.stateService.audioEnabled = false;
  // }

  public newRemoteFeed(sources: any, pin?: string): void {
    const subscription: any = [];
    for (const s in sources) {
      const streams = sources[s];
      for (const i in streams) {
        const stream = streams[i];
        // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
        if (
          stream.type === 'video' &&
          Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
          (stream.codec === 'vp9' || (stream.codec === 'vp8' && !Janus.safariVp8))
        ) {
          console.warn('Publisher is using ' + stream.codec.toUpperCase + ', ' +
            'but Safari doesn`t support it: disabling video stream #' + stream.mindex);
          continue;
        }
        if (stream.disabled) {
          Janus.log('Disabled stream:', stream);
          // TODO Skipping for now, we should unsubscribe
          continue;
        }
        Janus.log('Subscribed to ' + stream.id + '/' + stream.mid + '?', this.subscriptions);

        if (this.subscriptions[stream.id] && this.subscriptions[stream.id][stream.mid]) {
          Janus.log('Already subscribed to stream, skipping:', stream);
          continue;
        }

        subscription.push({
          feed: stream.id, // This is mandatory
          mid: stream.mid // This is optional (all streams, if missing)
        });
        if (!this.subscriptions[stream.id]) {
          this.subscriptions[stream.id] = {};
        }
        this.subscriptions[stream.id][stream.mid] = true;
      }
    }
    console.log(subscription, '--------subscription------ATTACH!!!!');
    // A new feed has been published, create a new plugin handle and attach to it as a subscriber
    this.janus.attach({
      plugin: 'janus.plugin.videoroom',
      success: (pluginHandle: JanusJS.PluginHandle) => {
        this.remoteFeedPlugin = pluginHandle;
        this.remoteTracks = {};
        // We wait for the plugin to send us an offer

        const subscribe = {
          request: 'join',
          room: this.stateService.roomId,
          ptype: 'subscriber',
          streams: subscription,
          pin,
          keyframe: true,
        };
        this.remoteFeedPlugin.send({ message: subscribe });
      },
      error: (error: string) => {
        console.error('[newRemoteFeed] Can\'t attach video room plugin to Janus error: ', error);
        this.notificationService.showError('Problem happened in media communication. Can\'t subscribe to a participant.');
      },
      onmessage: (msg: any, jsep: any) => {
        const event = msg.videoroom;
        if (msg.error) {
          console.error('[msg] Error', msg.error);
        } else if (event) {
          if (event === 'attached') {
            // Subscriber created and attached
            this.creatingSubscription = false;
            // if (!remoteFeed.spinner) {
            //   // var target = document.getElementById('videoremote'+remoteFeed.rfindex);
            //   // remoteFeed.spinner = new Spinner({top:100}).spin(target);
            // } else {
            //   // remoteFeed.spinner.spin();
            // }
            //
            // // $('#remote'+remoteFeed.rfindex).removeClass('hide').html(remoteFeed.rfdisplay).show();
          } else if (event === 'event') {
            // Check if we got an event on a simulcast-related event from this publisher
            const substream = msg.substream;
            if (substream !== null && substream !== undefined) {
              console.log('[R] substream applied to: ', substream);
            }
          } else {
            // What has just happened?
          }
        }

        if (msg.streams) {
          // Update map of subscriptions by mid
          for (const i in msg.streams) {
            const mid = msg.streams[i].mid;
            this.subStreams[mid] = msg.streams[i];
          }
        }

        if (jsep) {
          // Answer and attach
          this.remoteFeedPlugin.createAnswer({
            jsep,
            media: { audioSend: false, videoSend: false, data: false }, // We want recvonly audio/video TODO: !!!!
            success: (jsepObj: JanusJS.JSEP) => {
              this.remoteFeedPlugin.send({
                message: { request: 'start', room: this.stateService.roomId, pin },
                jsep: jsepObj,
              });
            },
            error: (error: JanusErrorDto) => {
              console.error('[createAnswer] WebRTC error', error);
            },
          });
        }
      },
      iceState: (state: string) => {
        // console.log('[S] ICE state of this WebRTC PC (feed #' + remoteFeed.rfindex + ') changed to ' + state);
        if (state === 'connected') {
          AnalyticsService.addPeerConnection(this.remoteFeedPlugin.webrtcStuff.pc);
        }
      },
      webrtcState: (on: boolean) => {
        //console.log('[S] Janus says this WebRTC PC (feed #' + remoteFeed.rfindex + ') is ' + (on ? 'up' : 'down') + ' now');
      },
      ondataopen: (data: string) => {
        console.log('dc [Subscriber] The DataChannel is available! data: ', data);
      },
      ondata: (data: any) => {
      },
      onremotetrack: (mediaStreamTrack: MediaStreamTrack, mid: number, on: boolean) => {
        // Janus.debug('Remote track (mid=' + mid + ') ' + (on ? 'added' : 'removed') + ':', mediaStreamTrack);
        // Which publisher are we getting on this mid?
        const sub = this.subStreams[mid];
        const feed = this.feedStreams[sub.feed_id];
        let stream;
        Janus.debug(' >> This track is coming from feed ' + sub.feed_id + ':', feed);
        if (!on) {
          // Track removed, get rid of the stream and the rendering
          stream = this.remoteTracks[mid];
          if (stream) {
            try {
              const tracks = stream.getTracks();
              for (const i in tracks) {
                const mst = tracks[i];
                if (mst) {
                  mst.stop();
                }
              }
            } catch (e) {
            }
          }
          if (mediaStreamTrack.kind === 'video' && feed) {
            feed.remoteVideos--;
            if (feed.remoteVideos === 0) {
              // No video, at least for now: show a placeholder
            }
          }
          delete this.remoteTracks[mid];
          return;
        }

        if (mediaStreamTrack.kind === 'audio') {
          // New audio track: create a stream out of it, and use a hidden <audio> element
          this.audioMediaStreamTracks = [];
          stream = new MediaStream();
          stream.addTrack(mediaStreamTrack.clone());
          const feedId = sub.feed_id;
          this.remoteTracks[mid] = stream;
          const remoteVideoIndex = this.videos.findIndex(({ video }: MultiMedia) => video.id === feed.id);
          if (remoteVideoIndex !== -1) {
            const knownId = this.userIds.find(userId => userId === feed.id); // check if no publisher with this remoteFeed.rfid
            if (!knownId) {
              return;
            }
            this.videos[remoteVideoIndex].audio = { stream: this.remoteTracks[mid], id: feedId };
          } else {
            const knownId = this.userIds.find(userId => userId === feed.id); // check if no publisher with this remoteFeed.rfid
            if (!knownId) {
              return;
            }
            this.videos.forEach(item => {
              if (!item.audio) {
                this.audioMediaStreamTracks = [
                  ...this.audioMediaStreamTracks,
                  {
                    stream: this.remoteTracks[mid],
                    id: feedId,
                  },
                ];
              }
            });
          }
          console.log('Created remote audio stream:', stream);
          if (feed.remoteVideos === 0) {
            // No video, at least for now: show a placeholder
          }
        }
        if (mediaStreamTrack.kind === 'video') {
          // New video track: create a stream out of it
          feed.remoteVideos++;
          stream = new MediaStream();
          /* eslint-disable-next-line  @typescript-eslint/naming-convention */
          const { feed_display, feed_id } = sub;

          stream.addTrack(mediaStreamTrack.clone());
          console.log('Created remote video stream:', stream);
          this.remoteTracks[mid] = stream;
          const isRemoteFeedExists = this.videos.find(({ video }: MultiMedia) => feed.id === video?.id);
          if (!isRemoteFeedExists) {
            const knownId = this.userIds.find(userId => userId === feed.id); // check if no publisher with this remoteFeed.rfid
            if (!knownId) {
              return;
            }
            this.rtdbSubscriptionsService.addUserInfo(feed_id, feed_display);
            // TODO define a proper state of cam and mic
            this.videos = [
              ...this.videos,
              {
                video: {
                  stream: this.remoteTracks[mid],
                  pluginHandle: this.remoteFeedPlugin,
                  id: feed_id,
                  name: feed_display,
                  remote: true,
                  type: VideoType.video, // TODO: determine is it video or screen
                },
              },
            ];
          }
        }

        this.videos.forEach((item, index) => {
          if (this.audioMediaStreamTracks.length && !item.audio && index > 0) {
            const ids = this.audioMediaStreamTracks.map(v => v.id);
            const emptyVideoItemIndex = this.videos.findIndex(({ video }: MultiMedia) => ids.includes(video.id));
            if (emptyVideoItemIndex !== -1) {
              this.videos[emptyVideoItemIndex].audio =
                this.audioMediaStreamTracks.find(track => track.id === this.videos[emptyVideoItemIndex].video.id);
            }
          }
        });
      },
      oncleanup: () => {
        this.remoteTracks = {};
      },
    });
  }

  public startShareScreen(isZwift: boolean = false): void {
    /*  const stream: MediaStream | null = this.localStream$.value;
      if (stream) {
        this.stopStream(stream);
      }
      this.videoRoomPlugin.createOffer({
        media: {
          video: 'screen', // Enable screen share
          audioSend: false, // Do or do not send audio
          videoRecv: false,
          replaceVideo: true,

        },
        success: (jsep: any) => {
          (this.videoRoomPlugin as any).send({ message: { audioSend: true, videoSend: true }, jsep });
          if (!this.stateService.isCamEnabled) {
            this.localScreenShareCamState = true;
            this.sendVideo(true);
          }
          this.sendDCScreenMessage(true);
        },
        error: (error: any) => {
          this.stopShareScreen();
          console.log(error, 'Notify user use about screen share issue.');
          this.stateService.isScreenShareEnabled$.next(false);
          this.popupService.dialog(CantShareYourScreenPopupComponent);
        },
      });
     */
    // TODO REPLACE VIDEO VARIANT
    // Screen share https://janus.conf.meetecho.com/screensharingtest.js?2
    this.janus.attach({
      plugin: 'janus.plugin.videoroom',
      success: (pluginHandle: JanusJS.PluginHandle) => {
        this.videoRoomScreenSharePlugin = pluginHandle;
        this.sendJoinRequestForScreenShare(this.videoRoomScreenSharePlugin, isZwift);
      },
      error: (error: string) => {
        console.error('Can\'t attach video room plugin to Janus for screen share, error: ', error);
        this.notificationService.showError('Problem happened in media communication. Can\'t publish screen.');
      },
      consentDialog: (on: boolean) => {
      },
      iceState: (state: string) => {
      },
      mediaState: (medium: 'audio' | 'video', on: boolean) => {
      },
      webrtcState: (on: boolean) => {
      },
      onmessage: (msg: any, jsep: any) => {
        // TODO: anys
        const event = msg.videoroom;

        if (event) {
          if (event === 'joined') {
            this.createOffer({ shareScreen: true });
          } else if (event === 'event') {
            console.log('[jS] Screen event', msg);
          }
        }

        if (jsep) {
          this.videoRoomScreenSharePlugin.handleRemoteJsep({ jsep });
        }
      },
      onlocaltrack: (mediaStreamTrack: MediaStreamTrack) => {
        console.log('[???] onlocaltrack', mediaStreamTrack);
        this.handleOnLocalTrackScreenShare(this.videoRoomScreenSharePlugin, mediaStreamTrack, isZwift);
      },
      oncleanup: () => {
      },
      detached: () => {
      },
    });
  }

  public stopShareScreen(): void {
    /*  const params: any = {
        changed: true,
        currentAudioDeviceId: null,
        currentVideoDeviceId: null,
        isAudioDeviceChanged: true,
        isVideoDeviceChanged: true,
        stream: this.localStream$.value
      };
      this.replaceStream(params);
      if (this.localScreenShareCamState) {
        this.sendVideo(false);
        this.localScreenShareCamState = false;
      }
     */
    // TODO REPLACE VIDEO VARIANT
    if (this.videoRoomScreenSharePlugin) {
      this.videoRoomScreenSharePlugin.detach(null);
    }

    this.rtdbSubscriptionsService.sendScreenShareState(null);
  }

  public getRandomId(): number {
    return Math.floor(1000000000000000 + Math.random() * 9000000000000000);
  }

  public switchShareScreen(on: boolean, isZwift: boolean = false): void {
    if (on) {
      this.startShareScreen(isZwift);
    } else {
      this.stopShareScreen();
    }
  }

  async switchSafariShareScreen(on: boolean, isZwift: boolean = false) {
    if (on) {
      this.createOffer({ shareScreen: true, safariScreenShare: true });
    } else {
      this.videoRoomScreenShareSafariPlugin.detach(null);
      this.attachScreenShareForSafariRoomPluginPromise = null;
      await this.attachScreenShareForSafariRoomPlugin(isZwift || this.stateService.isZwiftShareEnabled);
      this.rtdbSubscriptionsService.sendScreenShareState(null);
    }
  }

  public getVideoRoomPC() {
    return this.videoRoomPlugin.webrtcStuff.pc;
  }

  public finishCall(): void {
    if (this.videoRoomPlugin) {
      this.videoRoomPlugin.send({ message: { request: 'hangup' } });
      this.videoRoomPlugin.hangup();
    }
    this.stateService.isJoinedRoom = false;
    // TODO: clean this class properties
  }

  // private stopStream(stream: MediaStream): void {
  //   if (!stream) {
  //     return;
  //   }
  //
  //   stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
  // }

  private addCurrentUserFakeVideo(): void {
    console.log('addCurrentUserFakeVideo');
    this.videos = [
      ...this.videos,
      {
        video: {
          stream: new MediaStream(),
          pluginHandle: this.videoRoomPlugin,
          id: this.localUserId,
          name: this.userName,
          remote: false,
          type: VideoType.video,
        },
      }
    ];

    this.stateService.audioEnabled = false;
    this.stateService.videoEnabled = false;
  }

  // private isJson(str: string) {
  //   try {
  //     JSON.parse(str);
  //   } catch (e) {
  //     return false;
  //   }
  //   return true;
  // }

  private unsubscribeFrom(feedId: number) {
    this.updateUsersCount();
    // Send an unsubscribe request
    const unsubscribe = {
      request: 'unsubscribe',
      streams: [{ feed: feedId }]
    };
    if (this.remoteFeedPlugin != null) {
      this.remoteFeedPlugin.send({ message: unsubscribe });
    }
    delete this.subscriptions[feedId];
  }

  public handleWebCamStream() {
    const settingData = {
      audioDeviceId: this.stateService.audioDeviceId || '',
      videoDeviceId: this.stateService.videoDeviceId || ''
    };

    this.replaceStream(settingData, false);
  }

  private handleBackgroundEffectChanges(): void {
    const localStream$ = this.localStream$.asObservable()
      .pipe(
        filter((value: MediaStream | null) => !!(value && value.getVideoTracks().length && !('canvas' in value.getVideoTracks()[0]))),
        distinctUntilChanged((first, second) => first?.getVideoTracks()[0].id === second?.getVideoTracks()[0].id)
      );
    const videoEnabled$ = this.rtdbSubscriptionsService.getUserInfo(this.localUserId, true).media$.asObservable()
      .pipe(
        filter(v => v !== null),
        pluck('video'),
        distinctUntilChanged()
      );

    combineLatest([localStream$, videoEnabled$, this.stateService.currentBackgroundEffect$.asObservable()])
      .pipe(filter(([localStream, hasVideo]) => !!(localStream && hasVideo)))
      .subscribe(([localStream, hasVideo, currentBackground]: [MediaStream | null, boolean, BackgroundEffectsType]) => {
        if (!localStream || !hasVideo) {
          return;
        }

        if (currentBackground !== BackgroundEffectsType.none) {
          this.videoEffectsService.sendChangedStream(this.videoRoomPlugin, localStream);
        } else {
          // TODO: resolve moment with this stop redrawing. Because right now it's not stopping when video turned off.
          this.videoEffectsService.stopRedrawingCanvas();
        }
      });
  }
}
