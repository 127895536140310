import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PopupRef } from '@shared/services/popup/popup.ref';
import { AnalyticsService } from '@shared/services/analytics.service';
import { User } from '../../../models/message';
import { AuthService } from '@shared/services/auth.service';
import { StateService } from '@shared/services';

@Component({
  selector: 'thevatra-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private dropDownRef: PopupRef | null;
  currentUser: User | null;
  displayName: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialogRef: PopupRef<ProfileComponent>,
    public auth: AuthService,
    public readonly stateService: StateService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.currentUser;
    this.displayName = this.currentUser?.displayName;
    AnalyticsService.addBreadcrumb('ui', 'Show profile popup.');
    this.dialogRef.overlay.backdropClick().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.close();
    });
    this.dialogRef.overlay.keydownEvents().pipe(takeUntil(this.destroyed$)).subscribe((event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        this.close();
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
    if (!!this.dropDownRef) {
      this.dropDownRef.close();
    }
  }

  public async updateProfile(displayName: string | undefined) {
    await this.auth.updateProfile(displayName);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
