import { NotificationMessageBase } from '@shared/services/notification/classes/base/notification-message.base';
import { NotificationType } from '@shared/services/notification/enums/notification-type';

const ERROR_BACKGROUND_COLOR = '#ec663b';

export class NotificationErrorMessage extends NotificationMessageBase {
  constructor(message: string) {
    super(message);
  }

  protected getNotificationType(): NotificationType {
    return NotificationType.warning;
  }

  protected getNotificationBackgroundColor(): string {
    return ERROR_BACKGROUND_COLOR;
  }
}
