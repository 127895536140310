import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ReactionsService } from '@shared/services/reactions.service';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { Observable, Subject, timer } from 'rxjs';
import { Reaction, User } from '../../../models/message';
import { AuthService } from '@shared/services/auth.service';
import { StateService } from '@shared/services';
import { RealtimeDatabaseService } from '../../../services/rtdb.service';

@Component({
  selector: 'thevatra-like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LikeComponent implements OnInit, OnDestroy {
  @Input() myLike: string;
  @Input() myId: string | number;
  destroyed$ = new Subject<boolean>();
  currentUser: User | null;
  userId = '';
  users: any = {};
  likes: Reaction[] = [];
  liked = false;
  starred = false;
  id: string;
  myStars: string[] = [];
  myLikes: string[] = [];
  private roomName: string | null;
  disabled = false;

  constructor(
    private readonly reactionsService: ReactionsService,
    public authService: AuthService,
    public readonly stateService: StateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public auth: AuthService,
    public reactions: ReactionsService,
    private readonly rtdb: RealtimeDatabaseService,
  ) { }

  ngOnInit(): void {
    this.roomName = this.stateService.roomName;
    this.currentUser = this.auth.currentUser;
    this.id = this.currentUser?.id as string;
    this.getUser$(this.currentUser?.id).pipe(
      takeUntil(this.destroyed$),
    ).subscribe((likes: Reaction[]) => {
      this.likes = likes;
      this.liked = this.likes[0]?.myLikes?.includes(this.myLike);
      this.myLikes = this.likes[0]?.myLikes || [];
      this.starred = this.likes[0]?.myStars?.includes(this.myLike);
      this.myStars = this.likes[0]?.myStars || [];
      this.changeDetectorRef.detectChanges();
    });
  }

  getUser$(userId: any): Observable<any> {
    let unsubscribe = () => {};
    return new Observable(observer => {
      unsubscribe = this.reactionsService.getUser(userId).onSnapshot((snap) => {
        this.userId = snap.docs && snap.docs[0]?.id;
        observer.next(
          snap.docs.map(doc => ({ ...(doc.data() as any), user: {
            id: doc.data().user.uid,
            displayName: doc.data().user.displayName,
            photoURL: doc.data().user.photoURL
          },
          roomId: doc.data().roomId,
          userId: doc.data().userId,
          reactionId: doc.id,
          likeId: doc.data().likeId,
          myLikes: doc.data().myLikes,
          likedBy: doc.data().likedBy,
          myStars: doc.data().myStars,
          sentAt: new Date((doc.data() as any).sentAt?.seconds * 1000) })) as any
        );
      }, error => {
        console.log(error);
      });
    }).pipe(finalize(unsubscribe));
  }

  setTimer(event?: any) {
    this.stateService.isLikeButtonDisabled = true;
    this.disabled = true;
    event.stopPropagation();
    this.toggleReaction(this.myId).then();
    timer(10000).subscribe(() => {
      this.stateService.isLikeButtonDisabled = false;
      this.disabled = false;
      this.toggleReaction(null).then();
    });
  }

  async toggleReaction(likeId: string | number | null) {
    await this.reactionsService.toggleReaction(this.userId, this.myLike, this.liked, this.myLikes, likeId);
  }

  async toggleStar(event: any) {
    event.stopPropagation();
    await this.reactions.toggleStar(this.userId, this.myLike, this.starred, this.myStars);
  }

  async ngOnDestroy() {
    await this.reactionsService.toggleLike(this.myLike, null);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
