import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from '../../models/message';
import { TokenService } from '@shared/services/token.service';
import { StateService } from '@shared/services';

@Injectable()
export class TokenGuard implements CanActivate {
  constructor(private router: Router,
              private tokenService: TokenService,
              public stateService: StateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject): any => {
      const token = route.paramMap.get('token') as string;
      const roomName = route.paramMap.get('roomName') as string;
      this.tokenService.parseToken(token, roomName).then((user: User | null) => {
        if (user) {
          resolve(true);
        } else {
          reject(false);
          this.logout();
        }
      });
    });
  }

  logout() {
    this.stateService.isLoggedIn = false;
    this.stateService.userName = null;
    this.router.navigate(['/error'], { queryParams: {
      code: 4200,
      message: 'You are not allowed to join webinar.'
    } }).then();
  }
}
